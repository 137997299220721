import React from 'react';

import styled from '@emotion/styled';

import { getTimeFromScheduleDate } from '../../../utils/date';
import Input from '../../atoms/input';
import Section from '../../atoms/section';

import { colors } from '../../../styles/colors';

const Label = styled('label')`
  color: ${colors.smoke};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 6px;
`;

interface Props {
  endAt?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EndTimeInput: React.FC<Props> = (props: Props) => {
  const { endAt, onChange } = props;
  const separatedDate = endAt ? endAt.split('T') : [];
  const endTimeFormatted = getTimeFromScheduleDate(separatedDate);

  return (
    <Section>
      <Label htmlFor="end-time">End Time</Label>
      <Input.Text
        type="time"
        id="end-time"
        name="end-time"
        defaultValue={endTimeFormatted}
        onChange={onChange}
      />
    </Section>
  );
};

export default EndTimeInput;
