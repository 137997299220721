import { DoctorsData } from '../../types/doctors';
import {
  SCHEDULE_CREATE_FAILURE,
  SCHEDULE_CREATE_REQUEST,
  SCHEDULE_CREATE_SUCCESS,
  SCHEDULE_SET_APPOINTMENT_TYPE,
  SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES,
  SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES,
  SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES,
  SCHEDULE_CLEAR_APPOINTMENT_TYPES,
  SCHEDULE_SET_DOCTOR,
  SCHEDULE_SET_END_AT_TIME,
  SCHEDULE_SET_START_AT_TIME,
  ScheduleAppointmentSetDoctor,
  ScheduleCreateFailure,
  ScheduleCreateRequest,
  ScheduleCreateSuccess,
  ScheduleSetAppointmentType,
  ScheduleSetEndAtTime,
  ScheduleSetStartAtTime,
  ScheduleSetAppointmentTypesAdultServices,
  ScheduleSetAppointmentTypesMinorServices,
  ScheduleSetAppointmentTypesOtherServices,
  ScheduleClearAppointmentTypes,
} from './actions';

export function setDoctorAppointment(
  payload?: DoctorsData,
): ScheduleAppointmentSetDoctor {
  return {
    type: SCHEDULE_SET_DOCTOR,
    payload,
  };
}

export function setStartAtTime(payload: string): ScheduleSetStartAtTime {
  return {
    type: SCHEDULE_SET_START_AT_TIME,
    payload,
  };
}

export function setEndAtTime(payload: string): ScheduleSetEndAtTime {
  return {
    type: SCHEDULE_SET_END_AT_TIME,
    payload,
  };
}

export function setAppointmentType(
  payload: string,
): ScheduleSetAppointmentType {
  return {
    type: SCHEDULE_SET_APPOINTMENT_TYPE,
    payload,
  };
}

export function setAppointmentTypesAdultServices(payload: string[]): ScheduleSetAppointmentTypesAdultServices {
  return {
    type: SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES,
    payload,
  };
}

export function setAppointmentTypesMinorServices(payload: string[]): ScheduleSetAppointmentTypesMinorServices {
  return {
    type: SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES,
    payload,
  };
}

export function setAppointmentTypesOtherServices(payload: string[]): ScheduleSetAppointmentTypesOtherServices {
  return {
    type: SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES,
    payload,
  };
}

export function clearAppointmentTypesArray(payload: string[]): ScheduleClearAppointmentTypes {
  return {
    type: SCHEDULE_CLEAR_APPOINTMENT_TYPES,
    payload,
  };
}

export function startCreateScheduleRequest(): ScheduleCreateRequest {
  return {
    type: SCHEDULE_CREATE_REQUEST,
  };
}

export function setCreateScheduleSuccess(): ScheduleCreateSuccess {
  return {
    type: SCHEDULE_CREATE_SUCCESS,
  };
}

export function setCreateScheduleFailure(): ScheduleCreateFailure {
  return {
    type: SCHEDULE_CREATE_FAILURE,
  };
}
