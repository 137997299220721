import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

interface Props {
  isSearchable?: boolean;
}

export const Input = styled.input<Props>`
  border: 1px solid ${colors.silver};
  border-radius: 15px;
  font-family: proxima-nova, sans-serif;
  font-size: medium;
  height: 50px;
  padding: ${({ isSearchable }) => (isSearchable ? '0 2.5rem' : '0 24px')};

  &:focus {
    outline: 0;
    border-style: solid;
    border-width: 1.5px;
    border-color: ${colors.grayChateau};
  }

  &[type="time"] {
    border: 2px solid ${colors.silver}; 
    border-radius: 15px;  
    font-family: proxima-nova, sans-serif; 
    text-align: center;  
    cursor: pointer;  

    &::-webkit-datetime-edit-fields-wrapper {
      border-radius: 4px;  
    }
    &::-webkit-datetime-edit-text {
      color: ${colors.tundora};  
      padding: 0 0.3em;  
    }
    &::-webkit-datetime-edit-hour-field,
    &::-webkit-datetime-edit-minute-field {
      color: ${colors.tundora};  
    }
    &::-webkit-inner-spin-button {
      opacity: 0.5;  
    }
  }
`;

export const IconContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: ${colors.silverChalice};
`;

export const SpinnerContainer = styled.span`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: ${colors.silverChalice};
`;

export const ErrorContainer = SpinnerContainer;
