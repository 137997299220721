import { SchedulestData } from '../../types/schedules';
import { UpdateScheduleForm } from '../../types/schedules/forms';

export interface State {
  loading: boolean;
  error: boolean;
  schedule?: SchedulestData;
  form: UpdateScheduleForm;
}

export const initialState: State = {
  loading: false,
  error: false,
  form: {},
};
