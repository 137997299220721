import { Reducer } from 'react';

import { produce } from 'immer';

import { Action } from './actions';
import { State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'SCHEDULE_SET_DOCTOR':
      draft.form.doctor = action.payload;
      break;
    case 'SCHEDULE_SET_START_AT_TIME':
      draft.form.startAt = action.payload;
      break;
    case 'SCHEDULE_SET_END_AT_TIME':
      draft.form.endAt = action.payload;
      break;
    case 'SCHEDULE_SET_APPOINTMENT_TYPE':
      draft.form.appointmentType = action.payload;
      break;
    case 'SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES':
      draft.form.adultServices = action.payload
      break;
    case 'SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES':
      draft.form.minorServices = action.payload
      break;
    case 'SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES':
      draft.form.otherServices = action.payload
      break;
    case 'SCHEDULE_CLEAR_APPOINTMENT_TYPES':
      draft.form.adultServices = [];
      draft.form.minorServices = [];
      draft.form.otherServices = [];
      break;
    case 'SCHEDULE_CREATE_REQUEST':
      draft.loading = true;
      draft.error = false;
      break;
    case 'SCHEDULE_CREATE_SUCCESS':
      draft.loading = false;
      draft.error = false;
      break;
    case 'SCHEDULE_CREATE_FAILURE':
      draft.loading = false;
      draft.error = true;
      break;
  }
});

export default reducer;
