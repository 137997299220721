import React, { useState } from 'react';

import { ScheduleData } from '../../../types/schedules';
import { HiddenTableCell, TableCell, TableRow } from '../../atoms/table';
import ExamRoomCell from '../exam-room-cell';
import { getScheduleDate } from '../exam-rooms-row/util';
import { DownIcon, UpIcon } from './styles';
import SummarizedContent from '../summarized-content';


interface Props {
  date: string;
  schedulesByDate: ScheduleData[];
}

export const CollapsibleExamRoomsRow: React.FC<Props> = (props: Props) => {
  const { date, schedulesByDate } = props;
  const cells = [
    { examRoomIndex: 1 },
    { examRoomIndex: 2 },
    { examRoomIndex: 3 },
  ];

  const [isCollapsed, setIsCollapsed] = useState(true);

  const filterSchedulesByExamRoomIndex = (roomIndex: number) => {
    return schedulesByDate.filter(schedule => {
      return schedule.examRoomIndex === roomIndex;
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  }

  const renderCell = (cell: { examRoomIndex: number }, schedulesByExamRoom: ScheduleData[]) => {
    if (isCollapsed) {
      return (
        <React.Fragment>
            <SummarizedContent schedulesByExamRoom={schedulesByExamRoom} toggleCollapse={toggleCollapse} />
        </React.Fragment>
      );
    } else {
      return (
        <ExamRoomCell
          date={date}
          examRoomIndex={cell.examRoomIndex}
          schedule={schedulesByExamRoom.find(s => s.examRoomIndex === cell.examRoomIndex)}  
        />
      );
    }
  }

  const renderExpandedRows = () => {
    return schedulesByDate.flatMap((schedule, index) => {
      if (schedule.examRoomIndex !== null) {
        const schedulesByExamRoom = filterSchedulesByExamRoomIndex(schedule.examRoomIndex);
        return (
          <TableRow key={schedule.id}>
            <HiddenTableCell onClick={toggleCollapse}>
              {index === 0 ? <UpIcon /> : null}
            </HiddenTableCell>
            <TableCell>{getScheduleDate(date)}</TableCell>
            {cells.map((cell,cellIndex) => (
              <React.Fragment key={cellIndex}>
                  {renderCell(cell, schedulesByExamRoom)}
              </React.Fragment>
            ))}
          </TableRow>
        );
      }
    });
  }

  const renderCollapsedRow = () => {
    return (
      <TableRow>
        <HiddenTableCell onClick={toggleCollapse}><DownIcon /></HiddenTableCell>
        <TableCell>{getScheduleDate(date)}</TableCell>
        {cells.map((cell,cellIndex) => {
          const schedulesByExamRoom = filterSchedulesByExamRoomIndex(cell.examRoomIndex);
          return (
            <React.Fragment key={cellIndex}>
                <SummarizedContent schedulesByExamRoom={schedulesByExamRoom} toggleCollapse={toggleCollapse} />
            </React.Fragment>
          );
        })}
      </TableRow>
    );
  }

  return (
    <>
      {isCollapsed ? renderCollapsedRow() : renderExpandedRows()}
    </>
  );
};

export default CollapsibleExamRoomsRow;
