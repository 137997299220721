import React from 'react';

import ScheduleFormCreate from './schedule-form-create';
import ScheduleFormEdit from './schedule-form-edit';

const ScheduleForm = () => null;

ScheduleForm.Create = () => {
  return <ScheduleFormCreate />;
};

ScheduleForm.Edit = () => {
  return <ScheduleFormEdit />;
};

export default ScheduleForm;
