import styled from '@emotion/styled';
import { colors } from '@warbyparker/retail-design-system';

export const DoctorNameTextContainer = styled.div`
  display: flex;
  background-color: transparent;
  border: 2px solid ${colors.silver};
  border-radius: 6px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.stone};
  align-items: center;
  justify-content: space-between;
  padding-left: 1.125rem;
  padding-right: 1.15rem;

  &:hover {
    cursor: pointer;
    background-color: ${colors.silver};
  }
`;

export const EditContainer = styled.span`
  font-weight: 600;
  color: ${colors.blueHover};
`;
