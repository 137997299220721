import React from 'react';

import { ScheduleData } from '../../../types/schedules';
import { HiddenTableCell, TableCell, TableRow } from '../../atoms/table';
import ExamRoomCell from '../exam-room-cell';
import { getScheduleDate } from './util';

interface Props {
  date: string;
  schedulesByDate: ScheduleData[];
}

export const ExamRoomsRow: React.FC<Props> = (props: Props) => {
  const { date, schedulesByDate } = props;
  const cells = [
    { examRoomIndex: 1 },
    { examRoomIndex: 2 },
    { examRoomIndex: 3 },
  ];

  const filterSchedulesByExamRoomIndex = (roomIndex: number) => {
    return schedulesByDate.filter(schedule => {
      return schedule.examRoomIndex === roomIndex;
    });
  };

  return (
    <TableRow>
      <HiddenTableCell></HiddenTableCell>
      <TableCell>{getScheduleDate(date)}</TableCell>
      {cells.map(cell => {
        const schedulesByExamRoom = filterSchedulesByExamRoomIndex(cell.examRoomIndex);
        return(
        <ExamRoomCell
          key={cell.examRoomIndex}
          date={date}
          examRoomIndex={cell.examRoomIndex}
          schedule={schedulesByExamRoom[0]}
        />
      )})}
    </TableRow>
  );
};

export default ExamRoomsRow;
