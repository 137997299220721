import { useHttpClient } from '../../contexts/http-client';
import { SchedulestData } from '../../types/schedules';
import { UpdateScheduleRequestDTO } from '../../types/schedules/dto';
import environments from '../environments';

export const useSchedule = () => {
  const httpClient = useHttpClient();

  return async (id: string) => {
    const { apiUrl } = environments;
    const url = `${apiUrl}/api/v1/doctor/schedules/${id}`;
    const request = new Request(url);

    return await httpClient<SchedulestData>(request, {
      responseModifier: async res => {
        return await res.json();
      },
    });
  };
};

export const useUpdateSchedule = () => {
  const httpClient = useHttpClient();

  return async (id: string, data: UpdateScheduleRequestDTO) => {
    const { apiUrl } = environments;
    const url = `${apiUrl}/api/v1/doctor/schedules/${id}`;
    const request = new Request(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await httpClient<SchedulestData>(request, {
      responseModifier: async res => {
        return await res.json();
      },
    });
  };
};

export const useDeleteSchedule = () => {
  const httpClient = useHttpClient();

  return async (id: string) => {
    const { apiUrl } = environments;
    const url = `${apiUrl}/api/v1/doctor/schedules/${id}`;
    const request = new Request(url, { method: 'DELETE' });

    return await httpClient<{}>(request, {
      responseModifier: async res => {
        return await res.json();
      },
    });
  };
};
