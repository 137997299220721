import { Reducer } from 'react';

import { produce } from 'immer';

import { Action } from './actions';
import { State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'AUTH_REQUEST':
      draft.loading = true;
      break;
    case 'AUTH_SUCCESS':
      draft.isAuthenticated = true;
      draft.accessToken = action.payload;
      draft.loading = false;
      draft.error = null;
      break;
    case 'AUTH_FAILURE':
      draft.isAuthenticated = false;
      draft.loading = false;
      draft.accessToken = null;
      draft.error = 'Error authenticating';
      break;
  }
});

export default reducer;
