import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AuthRequest,
  AuthSuccess,
  AuthFailure,
} from './actions';

export function startAuthenticationRequest(): AuthRequest {
  return {
    type: AUTH_REQUEST,
  };
}

export function setAuthenticationSuccess(payload: string): AuthSuccess {
  return {
    type: AUTH_SUCCESS,
    payload,
  };
}

export function setAuthenticationFailure(): AuthFailure {
  return {
    type: AUTH_FAILURE,
  };
}
