import { Reducer } from 'react';

import { produce } from 'immer';

import { Action } from './actions';
import { State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'USER_PROFILE_REQUEST':
      draft.loading = true;
      draft.error = false;
      break;
    case 'USER_PROFILE_SUCCESS':
      draft.userProfile = action.payload;
      draft.error = false;
      draft.loading = false;
      break;
    case 'USER_PROFILE_FAILURE':
      draft.userProfile.user = null;
      draft.userProfile.environment = null;
      draft.userProfile.facility = null;
      draft.userProfile.features = [];
      draft.userProfile.permissions = [];
      draft.userProfile.retail_stores = [];
      draft.error = true;
      draft.loading = false;
      break;
    case 'USER_PROFILE_SET_OFFICE':
      draft.userProfile.office = action.payload;
      break;
  }
});

export default reducer;
