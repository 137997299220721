import { DoctorsData } from '../../types/doctors';

export const SCHEDULE_SET_DOCTOR = 'SCHEDULE_SET_DOCTOR';
export const SCHEDULE_SET_START_AT_TIME = 'SCHEDULE_SET_START_AT_TIME';
export const SCHEDULE_SET_END_AT_TIME = 'SCHEDULE_SET_END_AT_TIME';
export const SCHEDULE_SET_APPOINTMENT_TYPE = 'SCHEDULE_SET_APPOINTMENT_TYPE';
export const SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES = 'SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES';
export const SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES = 'SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES';
export const SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES = 'SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES';
export const SCHEDULE_CLEAR_APPOINTMENT_TYPES = 'SCHEDULE_CLEAR_APPOINTMENT_TYPES';
export const SCHEDULE_CREATE_REQUEST = 'SCHEDULE_CREATE_REQUEST';
export const SCHEDULE_CREATE_SUCCESS = 'SCHEDULE_CREATE_SUCCESS';
export const SCHEDULE_CREATE_FAILURE = 'SCHEDULE_CREATE_FAILURE';

export interface ScheduleAppointmentSetDoctor {
  type: typeof SCHEDULE_SET_DOCTOR;
  payload: DoctorsData | undefined;
}

export interface ScheduleSetStartAtTime {
  type: typeof SCHEDULE_SET_START_AT_TIME;
  payload: string;
}

export interface ScheduleSetEndAtTime {
  type: typeof SCHEDULE_SET_END_AT_TIME;
  payload: string;
}

export interface ScheduleSetAppointmentType {
  type: typeof SCHEDULE_SET_APPOINTMENT_TYPE;
  payload: string;
}

export interface ScheduleSetAppointmentTypesAdultServices {
  type: typeof SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES;
  payload: string[];
}

export interface ScheduleSetAppointmentTypesMinorServices {
  type: typeof SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES;
  payload: string[];
}

export interface ScheduleSetAppointmentTypesOtherServices {
  type: typeof SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES;
  payload: string[];
}

export interface ScheduleClearAppointmentTypes {
  type: typeof SCHEDULE_CLEAR_APPOINTMENT_TYPES;
  payload: string[];
}

export interface ScheduleCreateRequest {
  type: typeof SCHEDULE_CREATE_REQUEST;
}

export interface ScheduleCreateSuccess {
  type: typeof SCHEDULE_CREATE_SUCCESS;
}

export interface ScheduleCreateFailure {
  type: typeof SCHEDULE_CREATE_FAILURE;
}

export type Action =
  | ScheduleAppointmentSetDoctor
  | ScheduleSetAppointmentType
  | ScheduleClearAppointmentTypes
  | ScheduleSetAppointmentTypesAdultServices
  | ScheduleSetAppointmentTypesMinorServices
  | ScheduleSetAppointmentTypesOtherServices
  | ScheduleSetStartAtTime
  | ScheduleSetEndAtTime
  | ScheduleCreateRequest
  | ScheduleCreateSuccess
  | ScheduleCreateFailure;
