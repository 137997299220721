import React, { InputHTMLAttributes } from 'react';

import SearchableInput from './searchable-input';
import { Input as StyledInput } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

interface InputTextProps extends Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface InputSearchProps extends Props {
  loading: boolean;
  error: boolean;
}

const Input = () => null;

Input.Text = (props: InputTextProps) => {
  const { ...rest } = props;
  return <StyledInput {...rest} />;
};

Input.Search = (props: InputSearchProps) => {
  const { loading, ...rest } = props;
  return <SearchableInput loading={loading} {...rest} />;
};

export default Input;
