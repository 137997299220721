import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';
interface Props {
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 50px;
  border: solid 2px ${colors.silver};
  border-radius: 15px;
  justify-content: center;
  align-items: center;
`;
export const RadioLabel = styled.label<Props>`
  color: ${colors.stone};
  font-size: 14px;
  font-weight: 600;
  margin-left: 0.5rem;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')}; 
`;

export const RadioInput = styled.input<Props>`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  height: 1.125rem;
  width: 1.125rem;
  border: 2px solid ${colors.grayChateau};
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')}; 

  &:checked {
    background-color: ${colors.darkBlue};
  }
`;
