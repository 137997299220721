import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { CaretDown24Icon, CaretUp24Icon } from '@warbyparker/retail-design-system';


export const DownIcon = styled(CaretDown24Icon)`
  fill: #000;
  width: 16px;
  height: 16px; 

  &:hover {
    fill: #000;
    cursor: pointer;
  }
`;

export const UpIcon = styled(CaretUp24Icon)`
  fill: #000;
  width: 16px;
  height: 16px;
`;