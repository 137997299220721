import { HTMLAttributes } from 'react';

import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

export interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  columnIndex?: number;
}

export const FacilityTableContainer = styled.div`
  margin-top: 0;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  
`;
export const TableContainer = styled.div`
  margin-top: 0;
  width: 700px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  
`;


export const TableWrapper = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 0 0 0 2px ${colors.silver};
  overflow: hidden;
`;

export const TableHeader = styled.th`
  padding: 10px;
  border-bottom: 2px solid ${colors.silver};
  background-color: ${colors.silk};
  font-weight: 400;
  text-align: left;
  color: ${colors.grayChateau};
  &:not(:last-child) {
    border-right: 1px solid ${colors.silver};
  }
`;
export const FacilityTableHeader = styled.th`
  padding: 10px;
  border-bottom: 2px solid ${colors.silver};
  background-color: ${colors.silk};
  font-weight: 400;
  text-align: center;
  color: ${colors.grayChateau};
  &:not(:last-child) {
    border-right: 1px solid ${colors.silver};
  }
`;
export const HiddenTableHeader = styled.th`
  padding: 10px;
  border-bottom: 2px solid ${colors.silver};
  background-color: ${colors.silk};
  font-weight: 400;
  text-align: left;
 
`;

export const TableRow = styled.tr`
  &:nth-of-type(even) {
    background-color: ${colors.silk};
  }
`;


export const TableCell = styled.td<TableCellProps>`
  padding: 10px;
  border: 0 solid ${colors.gray};
  background-color: ${colors.white};
  font-size: 14px;
  border-bottom: 1px solid ${colors.silver};
  border-top: 1px solid ${colors.silver};
  color: ${({ columnIndex }) =>
    columnIndex && columnIndex >= 1 && columnIndex <= 3
      ? colors.darkBlue
      : colors.charcoal};
  font-weight: ${({ columnIndex }) =>
    columnIndex && columnIndex >= 1 && columnIndex <= 3 ? 'bold' : 'none'};
  cursor: ${({ columnIndex }) =>
    columnIndex && columnIndex >= 1 && columnIndex <= 3 ? 'pointer' : 'auto'};
  &:not(:last-child) {
    border-right: 1px solid ${colors.silver};
  }
`;
export const HiddenTableCell = styled.td<TableCellProps>`
  padding: 10px;
  border: 0 solid ${colors.gray};
  background-color: ${colors.white};
  font-size: 14px;
  border-bottom: 1px solid ${colors.silver};
`;

export const NoDataCell = styled.td`
  padding: 10px;
  text-align: center;
  background-color: white;
  font-style: italic;
  color: #888;
  &:not(:last-child) {
    border-right: 1px solid #ccc;
  }
`;

export const TableSectionHeader = styled.tr`
  display: flex;
  font-weight: bold;
  height: 2rem;
  text-align: start;
  align-items: center;
  padding-left: 1rem;
  font-size: 14px;
  
`;

