import {
  SCHEDULES_CHANGE_DISABLED,
  SCHEDULES_CHANGE_SHORT_NAME,
  SCHEDULES_CHANGE_NAME,
  SchedulesChangeDisabled,
  SchedulesChangeShortName,
  SchedulesChangeFacilityName,
} from './actions';

export function setDisabled(payload: boolean): SchedulesChangeDisabled {
  return {
    type: SCHEDULES_CHANGE_DISABLED,
    payload,
  };
}

export function setFacilityName(payload: string): SchedulesChangeFacilityName {
  return {
    type: SCHEDULES_CHANGE_NAME,
    payload,
  };
}

export function setFacilityShortName(
  payload: string,
): SchedulesChangeShortName {
  return {
    type: SCHEDULES_CHANGE_SHORT_NAME,
    payload,
  };
}
