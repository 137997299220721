import React, { useState } from 'react';

import { DefaultOption } from '../../../types/schedules';
import { TableCell, TableRow, TableSectionHeader } from '../../atoms/table';
import Checkbox from '../../atoms/checkbox';

interface Props {
  services: DefaultOption[];
  onCheckedServicesChange: (newCheckedServices: { [key: string]: boolean }) => void;
}

export const MinorServicesSection: React.FC<Props> = (props: Props) => {
  const { services, onCheckedServicesChange } = props;

  const [checkedServices, setCheckedServices] = useState<{ [key: string]: boolean }>({});

  const handleCheckboxChange = (productName: string, isChecked: boolean) => {
    const newCheckedServices = {
      ...checkedServices,
      [productName]: isChecked,
    };
    setCheckedServices(newCheckedServices);
    onCheckedServicesChange(newCheckedServices);
  };

  if (!services.length) return null;

  return (
    <React.Fragment>
      <TableSectionHeader>
        <td>MINOR</td>
      </TableSectionHeader>
      {services.map(({ productName }) => (
        <TableRow key={productName}>
          <TableCell>{productName}</TableCell>
          <TableCell columnIndex={1}>
            <Checkbox
               checked={checkedServices[productName] || false}
               onChange={(event) => handleCheckboxChange(productName, event.target.checked)}
            />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
};

export default MinorServicesSection;
