import React, { useState } from 'react';

import { DoctorsData } from '../../../types/doctors';
import Section from '../../atoms/section';
import DoctorNameTextField from '../doctor-name-text-field';
import DoctorSearchInput from './doctor-search-input';
import { Label } from './styles';
import { filterDoctorsWithFullName } from './util';
interface Props {
  doctor?: DoctorsData;
  onUpdateDoctor: (doctor?: DoctorsData) => void;
  doctorsByFacility: DoctorsData[];
}

const DoctorNameInput: React.FC<Props> = (props: Props) => {
  const { doctor, onUpdateDoctor, doctorsByFacility } = props;
  const [searchResult, setSearchResult] = useState<DoctorsData[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onInitDoctorSearch = (
    event: React.ChangeEvent<HTMLInputElement>,
    doctors: DoctorsData[]
  ): void => {
    const query: string = event.target.value;
    const hasQuery: boolean = query.length > 0;

    if (hasQuery &&  doctors.length) {
      const filteredDoctors: DoctorsData[] = filterDoctorsWithFullName(doctors, query);
      setSearchResult(filteredDoctors);
      setOpen(true);
      setLoading(false);
      setError(false);
    } 
    else {
      setOpen(false);
    }
  };

  const onEditDoctor = () => {
    onUpdateDoctor(undefined);
  };

  return (
    <Section>
      <Label htmlFor="doctor">Doctor</Label>
      {doctor ? (
        <DoctorNameTextField
          doctorFullName={`${doctor.firstName} ${doctor.lastName}`}
          onClick={onEditDoctor}
        />
      ) : (
        <DoctorSearchInput
          open={open}
          error={error}
          loading={loading}
          options={searchResult}
          onClick={onUpdateDoctor}
          onInitDoctorSearch={(event) => onInitDoctorSearch(event, doctorsByFacility)}
        />
      )}
    </Section>
  );
};

export default DoctorNameInput;
