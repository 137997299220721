import { Schedules } from '../../types/schedules';

export const SCHEDULES_REQUEST = 'SCHEDULES_REQUEST';
export const SCHEDULES_SUCCESS = 'SCHEDULES_SUCCESS';
export const SCHEDULES_FAILURE = 'SCHEDULES_FAILURE';
export const SCHEDULES_CHANGE_MONTH = 'SCHEDULES_CHANGE_MONTH';
export const SCHEDULES_CHANGE_YEAR = 'SCHEDULES_CHANGE_YEAR';

export interface SchedulesRequest {
  type: typeof SCHEDULES_REQUEST;
}

export interface SchedulesSuccess {
  type: typeof SCHEDULES_SUCCESS;
  payload: Schedules;
}

export interface SchedulesFailure {
  type: typeof SCHEDULES_FAILURE;
}

export interface SchedulesChangeMonth {
  type: typeof SCHEDULES_CHANGE_MONTH;
  payload: number;
}

export interface SchedulesChangeYear {
  type: typeof SCHEDULES_CHANGE_YEAR;
  payload: number;
}

export type Action =
  | SchedulesRequest
  | SchedulesSuccess
  | SchedulesFailure
  | SchedulesChangeMonth
  | SchedulesChangeYear;
