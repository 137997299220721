import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

const IconContainer = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: ${colors.charcoal};
  background: none;
  border: none;
  padding: 6px;
`;

export default IconContainer;
