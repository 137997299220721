export enum ServicesProfiles {
  Adult = 'ADULT',
  Minor = 'MINOR',
  Other = 'OTHER',
}

export enum ScheduleConfigurations {
  InPerson = 'IN_PERSON',
  VideoAssisted = 'VIDEO_ASSISTED',
  NoDoctor = 'NO_DOCTOR',
}

export enum AppointmentTypes {
  InPerson = 'IN_PERSON',
  VideoAssisted = 'VIDEO_ASSISTED',
  NoDoctor = 'no-doctor',
}

interface AppointmentTypeMap {
  appointmentType: AppointmentTypes;
  configuration: ScheduleConfigurations;
}

export const appointmentTypesMapper: AppointmentTypeMap[] = [
  {
    appointmentType: AppointmentTypes.InPerson,
    configuration: ScheduleConfigurations.InPerson,
  },
  {
    appointmentType: AppointmentTypes.VideoAssisted,
    configuration: ScheduleConfigurations.VideoAssisted,
  },
  {
    appointmentType: AppointmentTypes.NoDoctor,
    configuration: ScheduleConfigurations.NoDoctor,
  },
];

export const APPOINTMENT_TYPE_INPUT_OPTIONS = Object.freeze([
  {
    id: 'VIDEO_ASSISTED',
    label: 'Video-assisted',
    value: AppointmentTypes.VideoAssisted,
  },
  {
    id: 'no-doctor',
    label: 'No Doctor',
    value: AppointmentTypes.NoDoctor,
  },
]);
