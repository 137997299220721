import { ScheduleData } from '../../../types/schedules';
import { splitScheduleTime } from '../../../utils/date';

export const getExamRoomDisplayText = (schedule: ScheduleData): string => {
  const { doctor, start, end } = schedule;
  const doctorFullName = `${doctor.firstName} ${doctor.lastName}`;
  return `${doctorFullName} ${splitScheduleTime(start, end)}`;
};

export const getNoCoverageText = (): string => {
  return 'No Coverage';
};
