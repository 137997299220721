import { useEffect } from 'react';

type PageElements = HTMLElement | Document;
type PageElementEvents<T extends PageElements> = T extends HTMLElement
  ? HTMLElementEventMap
  : DocumentEventMap;

type UseEventListenerFn = <
  TElement extends PageElements,
  TEvent extends keyof PageElementEvents<TElement>,
>(
  element: TElement | null,
  event: TEvent,
  handler?: ((event: PageElementEvents<TElement>[TEvent]) => any) | null,
) => void;

export const useEventListener: UseEventListenerFn = (
  element,
  event,
  handler,
) => {
  useEffect(() => {
    const listener = (evt: any) => {
      if (handler) handler?.(evt);
    };

    element?.addEventListener(event as any, listener);

    return () => {
      element?.removeEventListener(event as any, listener);
    };
  }, [element, event, handler]);
};
