import React from 'react';

import AppRoutes from './routes/appRoutes';
import GlobalStyles from './styles/globalStyles';

export function App() {
  return (
    <>
      <GlobalStyles />
      <AppRoutes />
    </>
  );
}

export default App;
