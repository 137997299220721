import { useId } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps extends React.PropsWithChildren {
  element: HTMLElement;
}

const Portal: React.FC<PortalProps> = ({ element, children }) => {
  const portalId = useId();

  return createPortal(<>{children}</>, element, portalId);
};

export default Portal;
