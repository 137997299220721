import React from 'react';

import StateProvider from '../../../store/create-schedule-page/StateProvider';
import CreateSchedulePageRoot from './create-schedule-page-root';

const CreateSchedulePage: React.FC = () => {
  return (
    <StateProvider>
      <CreateSchedulePageRoot />
    </StateProvider>
  );
};

export default CreateSchedulePage;
