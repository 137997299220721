import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

const Page = styled('div')`
  background: ${colors.silk};
  height: 100%;
  width: 100%;
  left: 0;
  min-height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
`;

export default Page;
