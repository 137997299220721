import React, { useEffect } from 'react';

import { useAppointmentTypes } from '../../../data-access/appointment-types/use-appointment-types';
import {
  setFacilityServicesFailure,
  setFacilityServicesSuccess,
  startFacilityServicesRequest,
} from '../../../store/facility-services/actions-creators';
import { useDispatchFacilityServices } from '../../../store/facility-services/StateProvider';
import {
  setFacilityShortName,
  setFacilityName,
} from '../../../store/header-component/action-creators';
import {
  useLocalDispatch,
  useLocalState,
} from '../../../store/header-component/StateProvider';
import { useUserProfile } from '../../../store/user-profile/StateProvider';
import { AppointmentTypes } from '../../../types/schedules';
import { Facility } from '../../../types/userProfile';
import HeaderBackButton from '../../molecules/header-back-button';
import FacilityPicker from '../facility-picker';
import {
  ButtonRigthContainer,
  StyledHeader,
  WidthSetter,
  Title,
  FacilityContainer,
  FacilityNameLabel,
} from './styles';

export interface Props {
  homeUrl?: string;
  disabled?: boolean;
}

const Header: React.FC<Props> = props => {
  const dispatch = useLocalDispatch();
  const { facilityName } = useLocalState();
  const { userProfile } = useUserProfile();
  const { facility, retail_stores } = userProfile;
  const { homeUrl, disabled } = props;
  const dispatchFacilityServices = useDispatchFacilityServices();
  const getAppointmentTypes = useAppointmentTypes();

  useEffect(() => {
    if (facility && !facilityName) {
      dispatch(setFacilityName(facility.name));
    }
  }, [facility]);

  const onFacilityServicesRequest = (shortName: string) => {
    dispatchFacilityServices(startFacilityServicesRequest());
    getAppointmentTypes(shortName)
      .then(onFacilityServicesSuccess)
      .catch(onFacilityServicesFailure);
  };

  const onFacilityServicesSuccess = (response: AppointmentTypes) => {
    dispatchFacilityServices(setFacilityServicesSuccess(response));
  };

  const onFacilityServicesFailure = () => {
    dispatchFacilityServices(setFacilityServicesFailure());
  };

  const onChange = (option: string, store: Facility) => {
    const { short_name } = store;
    dispatch(setFacilityShortName(short_name));
    dispatch(setFacilityName(option));
    onFacilityServicesRequest(short_name);
  };

  return (
    <StyledHeader>
      <WidthSetter>
        <HeaderBackButton homeUrl={homeUrl} />
        <Title>
          <FacilityContainer>
            <FacilityNameLabel>
              Doctor schedules: {facilityName}
            </FacilityNameLabel>
          </FacilityContainer>
        </Title>

        <ButtonRigthContainer>
          {!disabled && (
            <FacilityPicker
              retailStores={retail_stores}
              onChange={onChange}
              disabled={disabled || false}
            />
          )}
        </ButtonRigthContainer>
      </WidthSetter>
    </StyledHeader>
  );
};

export default Header;
