export interface State {
  isAuthenticated: boolean;
  accessToken: string | null;
  loading: boolean;
  error: string | null;
}

export const initialState: State = {
  isAuthenticated: false,
  accessToken: null,
  loading: false,
  error: null,
};
