import styled from '@emotion/styled';
import { MenuIcon } from '@warbyparker/retail-design-system';
import { DropdownMenuProps } from './index';
import { colors } from '../../../styles/colors';


export const DropdownIcon = styled(MenuIcon)`
  fill: #000;
  width: 16px;
  height: 16px; 
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const DropdownMenuItem = styled.li<{ disabled: boolean }>`
  &:hover {
    background-color: ${colors.blueHover};
    color: ${colors.white};
  }
  width: 300px;
  height: 30px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grayChateau};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  &:not(:last-child) {
    border-bottom: none;
  }
`;

export const DropdownMenu = styled.ul<DropdownMenuProps>`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 300px;
  border-radius: 4px;
`;
