import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '@warbyparker/retail-design-system';

import {
  useDeleteSchedule,
  useUpdateSchedule,
} from '../../../data-access/schedules/use-schedule';
import {
  setSchedulestType,
  setDoctorSchedulest,
  setEndAtTime,
  setStartAtTime,
  setEditSchedulestSuccess,
  setEditSchedulestFailure,
  starEditSchedulestRequest,
  setAppointmentTypesAdultServices,
  setAppointmentTypesMinorServices,
  setAppointmentTypesOtherServices,
} from '../../../store/edit-appointment-page/action-creators';
import {
  useLocalDispatch,
  useLocalState,
} from '../../../store/edit-appointment-page/StateProvider';
import { useUserProfile } from '../../../store/user-profile/StateProvider';
import { ActionsMenuRef, ActionsMenu } from '../../atoms/actions-menu';
import SchedulesTypeInput from '../../molecules/appointment-type-input';
import { DeleteScheduleFailPopover } from '../../molecules/delete-schedule-fail-popover';
import { DeleteSchedulePopover } from '../../molecules/delete-schedule-popover';
import DoctorNameInput from '../../molecules/doctor-name-input';
import EndTimeInput from '../../molecules/end-time-input';
import StartTimeInput from '../../molecules/start-time-input';
import FacilityServicesTable from '../facility-services-table';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  ScheduleErrorMessage,
  CardTitleContainer,
  CardActionContainer,
} from './styles';
import { buildAndValidateSchedule, getEditScheduleSubtitle } from './util';

import type { DoctorsData } from '../../../types/doctors';
import type { ModalContainerRef } from '../../atoms/popover-modal';
import { useDoctorQuery } from '../../../data-access/doctors/use-doctor-query';
import { getKeysWithTrueValues } from '../facility-services-table/util';
import { BottomFooter } from '../../atoms/footer';

export const ScheduleFormEdit = () => {
  const deleteScheduleModalRef = useRef<ModalContainerRef>(null);
  const deleteScheduleFailModalRef = useRef<ModalContainerRef>(null);
  const actionsRef = useRef<ActionsMenuRef>(null);

  const { id } = useParams<{ id: string }>();
  const { userProfile } = useUserProfile();
  const { facility, office } = userProfile;
  const history = useHistory();
  const { loading, form, error, schedule } = useLocalState();
  const { doctor, startAt, endAt, appointmentType, appointmentTypes } = form;

  const dispatch = useLocalDispatch();

  const updateSchedules = useUpdateSchedule();
  const deleteSchedule = useDeleteSchedule();

  const searchDoctors = useDoctorQuery();
  const [doctorsByFacility, setDoctorsByFacility] = useState<DoctorsData[]>([]);

  const onChangeStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    dispatch(setStartAtTime(value));
  };

  const onChangeEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    dispatch(setEndAtTime(value));
  };

  const onUpdateDoctor = (doctor?: DoctorsData) => {
    dispatch(setDoctorSchedulest(doctor));
  };

  const onUpdate = () => {
    const { office } = userProfile;

    const newData = buildAndValidateSchedule({
      office: office || schedule?.officeId,
      scheduleDate: schedule?.start || null,
      startAt,
      endAt,
      doctor,
      examRoomIndex: schedule?.examRoomIndex,
      appointmentType,
      appointmentTypes,
    });

    if (!newData || !id) {
      onUpdateFailure();
      return;
    }

    dispatch(starEditSchedulestRequest());
    updateSchedules(id, newData).then(onUpdateSuccess).catch(onUpdateFailure);
  };

  const onUpdateSuccess = () => {
    dispatch(setEditSchedulestSuccess());
    history.replace('/');
  };

  const onUpdateFailure = () => {
    dispatch(setEditSchedulestFailure());
  };

  const onDeleteSchedule = useCallback(async () => {
    deleteScheduleModalRef.current?.openModal();
  }, [deleteScheduleModalRef]);

  const onDeleteScheduleConfirm = useCallback(async () => {
    try {
      await deleteSchedule(id);

      deleteScheduleModalRef.current?.closeModal();
      history.replace(`/?omitIds=${id}`);
    } catch (err) {
      console.error("Couldn't delete the schedule:", (err as Error).message);

      deleteScheduleModalRef.current?.closeModal();
      deleteScheduleFailModalRef.current?.openModal();
    }
  }, [deleteScheduleModalRef, deleteScheduleFailModalRef]);

  const onDeleteScheduleDismiss = useCallback(async () => {
    deleteScheduleModalRef.current?.closeModal();
  }, [deleteScheduleModalRef]);

  const onActionClick = useCallback<React.MouseEventHandler<HTMLElement>>(
    evt => {
      actionsRef.current?.handleOpen(evt);
    },
    [actionsRef],
  );

  const fetchDoctorsByFacility = async (shortName: string | undefined) => {
    try {
      return (await searchDoctors(shortName)).data
    } catch {
      return []
    }
  };

  const onInitDoctorsByFacilitySearch = async (shortName: string | undefined) => {
    if (!shortName) return;
    const data = await fetchDoctorsByFacility(shortName);
  
    setDoctorsByFacility(data);
  }

  useEffect(() => {
    onInitDoctorsByFacilitySearch(facility?.short_name);
  }, [facility?.short_name]);

  const onAppointmentTypes = (appointmentTypes?: string[]) => {
    if (!appointmentTypes) {
      return;
    }
  
    if (appointmentTypes.includes('ADULT')) {
      dispatch(setAppointmentTypesAdultServices(appointmentTypes));
    } else if (appointmentTypes.includes('MINOR')) {
      dispatch(setAppointmentTypesMinorServices(appointmentTypes));
    } else {
      dispatch(setAppointmentTypesOtherServices(appointmentTypes));
    }
  };

  const handleUpdatedCheckedServices = (newCheckedServices: { [key: string]: boolean }) => {
    const keysWithTrueValues = getKeysWithTrueValues(newCheckedServices);
    onAppointmentTypes(keysWithTrueValues);
  };


  return (
    <>
      <DeleteSchedulePopover
        onConfirm={onDeleteScheduleConfirm}
        onDismiss={onDeleteScheduleDismiss}
        ref={deleteScheduleModalRef}
      />
      <DeleteScheduleFailPopover ref={deleteScheduleFailModalRef} />
      <Card>
        <CardHeader>
          <CardTitleContainer>
            <CardActionContainer className="hidden" />
            <CardTitle>Edit Schedule</CardTitle>
            <CardActionContainer onClick={onActionClick}>
              <ActionsMenu ref={actionsRef} label="•••">
                <div onClick={onDeleteSchedule}>Delete Schedule</div>
              </ActionsMenu>
            </CardActionContainer>
          </CardTitleContainer>
          <CardSubtitle>{getEditScheduleSubtitle(schedule)}</CardSubtitle>
        </CardHeader>
        <CardBody>
          <DoctorNameInput onUpdateDoctor={onUpdateDoctor} doctor={doctor} doctorsByFacility={doctorsByFacility}/>
          <SchedulesTypeInput
            appointmentType={appointmentType}
            isRemote={doctor?.isRemote}
            onChange={(value: string) => dispatch(setSchedulestType(value))}
            onClear={() => dispatch(setSchedulestType(''))}
          />
          <StartTimeInput startAt={startAt} onChange={onChangeStartTime} />
          <EndTimeInput endAt={endAt} onChange={onChangeEndTime} />
          <FacilityServicesTable 
            appointmentType={appointmentType}             
            onServicesChange={handleUpdatedCheckedServices}
          />
        </CardBody>
      </Card>
      {error && (
        <ScheduleErrorMessage>
          Error updating schedule
        </ScheduleErrorMessage>
      )}
      <BottomFooter>
        <Button 
          onClick={onUpdate} 
          size="large" 
          style={{ width: '100%' }}
          disabled={loading}>
          Save
        </Button>
      </BottomFooter>
    </>
  );
};

export default ScheduleFormEdit;
