import { useHttpClient } from '../../contexts/http-client';
import { Doctors } from '../../types/doctors';
import environments from '../environments';

export const useDoctorQuery = () => {
  const httpClient = useHttpClient();

  return async (facilityShortName: string | undefined) => {
    const { apiUrl } = environments;
    const url = `${apiUrl}/api/v1/doctor-schedules/offices/by-short-name/${facilityShortName}/doctors`;
    const request = new Request(url);

    return await httpClient<Doctors>(request, {
      responseModifier: async res => {
        return await res.json();
      },
    });
  };
};
