import { Reducer } from 'react';

import { produce } from 'immer';

import { Action } from './actions';
import { State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'SCHEDULES_CHANGE_DISABLED':
      draft.disabled = action.payload;
      break;
    case 'SCHEDULES_CHANGE_NAME':
      draft.facilityName = action.payload;
      break;
    case 'SCHEDULES_CHANGE_SHORT_NAME':
      draft.facilityShortName = action.payload;
      break;
  }
});

export default reducer;
