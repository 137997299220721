import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

interface ActionMenuListProps {
  x: number;
  y: number;
}

export const ActionMenuList = styled.span<ActionMenuListProps>`
  * {
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  width: 180px;

  display: block;
  position: absolute;
  z-index: 9999;

  top: ${props => props.y}px;
  left: ${props => props.x}px;

  background: ${colors.white};
  border: 2px solid ${colors.silver};

  padding: 0.5rem 0;

  border-radius: 0.5rem;

  & > * {
    width: 100%;

    text-align: center;
    color: ${colors.tundora};
    background: ${colors.white};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding: 0.75rem 1rem;

    transition: all 0.2s ease-in;

    &:hover {
      cursor: pointer;
      color: ${colors.white};
      background: ${colors.darkBlue};
    }
  }
`;
