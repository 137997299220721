import { createContext, useContext } from 'react';

import { HttpClient } from '../../types/common';

const defaultValue: HttpClient = async () =>
  await Promise.reject('Unexpected use of HttpClient before initialization');

export const HttpClientContext = createContext(defaultValue);

export function useHttpClient() {
  return useContext(HttpClientContext);
}
