import { useHttpClient } from '../../contexts/http-client';
import { AppointmentTypes } from '../../types/schedules';
import environments from '../environments';

export const useAppointmentTypes = () => {
  const httpClient = useHttpClient();
  return async (shortName: string) => {
    const { apiUrl } = environments;
    const url = `${apiUrl}/api/v1/doctor-schedules/services-by-facility/${shortName}`;
    const request = new Request(url);

    return await httpClient<AppointmentTypes>(request, {
      responseModifier: async res => {
        return await res.json();
      },
    });
  };
};
