import styled from '@emotion/styled';
import { colors } from '../../../styles/colors';

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const HiddenCheckbox = styled.input`
  appearance: none;
  background-color: transparent; // Default background
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.blue}; // Adjust border color as needed
  border-radius: 2px;
  transform: translateY(1px);
  display: grid;
  place-content: center;

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    transform: scale(0);
    box-shadow: inset 16px 16px transparent;  // Make the checkmark transparent by default
    transition: 120ms transform ease-in-out;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked {
    background-color: ${colors.darkBlue}; // Background when checked
  }

  &:checked:before {
    box-shadow: inset 16px 16px ${colors.white};  // Checkmark color when checked
    transform: scale(1);
  }

  &:focus {
    outline: max(2px, 2.5px) solid currentColor;
    outline-offset: max(2px, 2.5px);
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.grayChateau};  
    border-color: currentColor;
    background-color: ${colors.grayChateau};
  }
`;
