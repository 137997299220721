import { ContentContainer } from './styles';

interface ModalContentProps extends React.HTMLAttributes<HTMLSpanElement> {}

export const ModalContent: React.FC<ModalContentProps> = ({
  children,
  ...props
}) => {
  return <ContentContainer {...props}>{children}</ContentContainer>;
};
