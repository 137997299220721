import styled from '@emotion/styled';

import loadingImage from '../../../assets/logo/loading.gif';

export const Loading = styled('div')`
  height: 100vh;
  width: 100%;
  background: white url(${loadingImage}) no-repeat fixed center;
`;

export const LoadingContainer = styled('div')`
  align-self: center;
`;

export const LoadingText = styled('h3')`
  font-size: 30px;
`;
