import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { Button } from '@warbyparker/retail-design-system';

import { useCreateSchedule } from '../../../data-access/schedules/use-create-schedule';
import {
  setAppointmentType,
  setAppointmentTypesAdultServices,
  setAppointmentTypesMinorServices,
  setAppointmentTypesOtherServices,
  setCreateScheduleFailure,
  setCreateScheduleSuccess,
  setDoctorAppointment,
  setEndAtTime,
  setStartAtTime,
  startCreateScheduleRequest,
} from '../../../store/create-schedule-page/action-creators';
import {
  useLocalDispatch,
  useLocalState,
} from '../../../store/create-schedule-page/StateProvider';
import { useUserProfile } from '../../../store/user-profile/StateProvider';
import { DoctorsData } from '../../../types/doctors';
import AppointmentTypeInput from '../../molecules/appointment-type-input';
import DoctorNameInput from '../../molecules/doctor-name-input';
import EndTimeInput from '../../molecules/end-time-input';
import StartTimeInput from '../../molecules/start-time-input';
import FacilityServicesTable from '../facility-services-table';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  ScheduleErrorMessage,
} from './styles';
import { buildAndValidateSchedule, getCreateScheduleSubtitle } from './util';
import { BottomFooter } from '../../atoms/footer';
import { useDoctorQuery } from '../../../data-access/doctors/use-doctor-query';
import { getKeysWithTrueValues } from '../facility-services-table/util';

const ScheduleFormCreate = () => {
  const { examRoomIndex } = useParams<{ examRoomIndex: string }>();
  const location = useLocation();
  const history = useHistory();

  const createSchedule = useCreateSchedule();
  const dispatch = useLocalDispatch();
  const searchDoctors = useDoctorQuery();

  const queryParams = new URLSearchParams(location.search);
  const scheduleDate = queryParams.get('scheduleDate');
  const { userProfile } = useUserProfile();
  const { facility, office } = userProfile;
  const { loading, form, error } = useLocalState();
  const { doctor, startAt, endAt, appointmentType, appointmentTypes, adultServices, minorServices, otherServices } = form;
  const [doctorsByFacility, setDoctorsByFacility] = useState<DoctorsData[]>([]);

  const onChangeStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    dispatch(setStartAtTime(value));
  };

  const onChangeEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    dispatch(setEndAtTime(value));
  };

  const onUpdateDoctor = (doctor?: DoctorsData) => {
    dispatch(setDoctorAppointment(doctor));
  };

  const fetchDoctorsByFacility = async (shortName: string | undefined) => {
    try {
      return (await searchDoctors(shortName)).data
    } catch {
      return []
    }
  };

  const onInitDoctorsByFacilitySearch = async (shortName: string | undefined) => {
    if (!shortName) return;
    const data = await fetchDoctorsByFacility(shortName);
  
    setDoctorsByFacility(data);
  }

  const onCreate = () => {
    const data = buildAndValidateSchedule({
      office,
      scheduleDate,
      startAt,
      endAt,
      doctor,
      examRoomIndex,
      appointmentType,
      adultServices,
      minorServices,
      otherServices
    });

    if (!data) {
      onCreationFailure();
      return;
    }

    dispatch(startCreateScheduleRequest());
    createSchedule(data).then(onCreationSuccess).catch(onCreationFailure);
  };

  const onCreationSuccess = () => {
    dispatch(setCreateScheduleSuccess());
    history.replace('/');
  };

  const onCreationFailure = () => {
    dispatch(setCreateScheduleFailure());
  };

  useEffect(() => {
    onInitDoctorsByFacilitySearch(facility?.short_name);
  }, [facility?.short_name]);


  const onAppointmentTypes = (appointmentTypes?: string[]) => {
    if (!appointmentTypes) {
      return;
    }
  
    if (appointmentTypes.includes('ADULT')) {
      dispatch(setAppointmentTypesAdultServices(appointmentTypes));
    } else if (appointmentTypes.includes('MINOR')) {
      dispatch(setAppointmentTypesMinorServices(appointmentTypes));
    } else {
      dispatch(setAppointmentTypesOtherServices(appointmentTypes));
    }
  };
  
  const handleUpdatedCheckedServices = (newCheckedServices: { [key: string]: boolean }) => {
    const keysWithTrueValues = getKeysWithTrueValues(newCheckedServices);
    onAppointmentTypes(keysWithTrueValues);
  };

  useEffect(() => {
    dispatch(setAppointmentType('IN_PERSON'))
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Create Schedule</CardTitle>
          <CardSubtitle>
            {getCreateScheduleSubtitle(scheduleDate, examRoomIndex)}
          </CardSubtitle>
        </CardHeader>
        <CardBody>
          <DoctorNameInput
            onUpdateDoctor={onUpdateDoctor}
            doctor={doctor}
            doctorsByFacility={doctorsByFacility}
          />
          <AppointmentTypeInput
            appointmentType={appointmentType}
            onChange={(value: string) => dispatch(setAppointmentType(value))}
            onClear={() => {
              dispatch(setAppointmentType('IN_PERSON'))
              dispatch(setAppointmentTypesAdultServices([]));
              dispatch(setAppointmentTypesMinorServices([]));
              dispatch(setAppointmentTypesOtherServices([]));
            }}
          />
          <StartTimeInput startAt={startAt} onChange={onChangeStartTime} />
          <EndTimeInput endAt={endAt} onChange={onChangeEndTime} />
          <FacilityServicesTable 
            appointmentType={appointmentType}
            onServicesChange={handleUpdatedCheckedServices}
          />
        </CardBody>
      </Card>
      {error && (
        <ScheduleErrorMessage>Error creating schedule</ScheduleErrorMessage>
      )}
      <BottomFooter>
        <Button
          size="large"
          disabled={loading}
          style={{ width: '100%' }}
          onClick={onCreate}
        >
          Save
        </Button>
      </BottomFooter>
    </>
  );
};

export default ScheduleFormCreate;
