import React, { useState } from 'react';

import {
  CaretLeft12Icon,
  CaretRight12Icon,
} from '@warbyparker/retail-design-system';

import {
  MonthFilterContainer,
  ButtonContainer,
  MonthLabel,
  ArrowButton,
} from './styles';

interface Props {
  loading: boolean;
  onNextMonth: (month: number) => void;
  onPreviousMonth: (month: number) => void;
}

const MonthFilter: React.FC<Props> = (props: Props) => {
  const { loading, onNextMonth, onPreviousMonth } = props;
  const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const onPreviousMonthCb = () => {
    const prevMonth = selectedMonth === 1 ? 12 : selectedMonth - 1;
    onPreviousMonth(prevMonth);
    setSelectedMonth(prevMonth);
  };

  const onNextMonthCb = () => {
    const nextMonth = selectedMonth === 12 ? 1 : selectedMonth + 1;
    onNextMonth(nextMonth);
    setSelectedMonth(nextMonth);
  };

  return (
    <MonthFilterContainer>
      <ButtonContainer>
        <ArrowButton disabled={loading} onClick={onPreviousMonthCb}>
          <CaretLeft12Icon />
        </ArrowButton>
        <MonthLabel>{months[selectedMonth - 1]}</MonthLabel>
        <ArrowButton disabled={loading} onClick={onNextMonthCb}>
          <CaretRight12Icon />
        </ArrowButton>
      </ButtonContainer>
    </MonthFilterContainer>
  );
};

export default MonthFilter;
