export const GENERIC_USER_MESSAGE = 'An error occurred';

export function handleUnauthorized(
  logout: (options?: any | undefined) => void,
) {
  return async (response: Response) => {
    if (!response.ok && (response.status === 401 || response.status === 403)) {
      logout();
    }

    return response;
  };
}

export async function handleResponse(response: Response) {
  if (!response.ok) {
    let res;

    try {
      res = await response.json();
    } catch (err) {
      throw new Error(GENERIC_USER_MESSAGE);
    }

    if (!res.message) {
      throw new Error(GENERIC_USER_MESSAGE);
    }

    throw new Error(res.message);
  }

  return response;
}
