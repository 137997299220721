import { DescriptionContainer } from './styles';

interface ModalDescriptionProps
  extends React.HTMLAttributes<HTMLParagraphElement> {}

export const ModalDescription: React.FC<ModalDescriptionProps> = ({
  children,
  ...props
}) => {
  return <DescriptionContainer {...props}>{children}</DescriptionContainer>;
};
