import { useHttpClient } from '../../contexts/http-client';
import environments from '../environments';

export const useUser = () => {
  const httpClient = useHttpClient();

  return async () => {
    const url = `${environments.apiUrl}/api/v1/user/me`;
    const request = new Request(url);

    return await httpClient<any>(request, {
      responseModifier: async res => {
        return await res.json();
      },
    });
  };
};
