import { forwardRef } from 'react';

import { Button } from '@warbyparker/retail-design-system';

import { ModalContainerRef, PopoverModal } from '../../atoms/popover-modal';
import { DismissButton } from './styles';

interface DeleteScheduleConfirmPopoverProps {
  onDismiss: () => void;
  onConfirm: () => void;
}

export const DeleteSchedulePopover = forwardRef<
  ModalContainerRef,
  DeleteScheduleConfirmPopoverProps
>((props, ref) => {
  const { onConfirm, onDismiss } = props;

  return (
    <PopoverModal.Container ref={ref}>
      <PopoverModal.Content>
        <PopoverModal.Title>Delete schedule</PopoverModal.Title>

        <PopoverModal.Description>
          You&apos;re about to delete the current schedule, do you want to
          proceed?
        </PopoverModal.Description>

        <PopoverModal.Actions>
          <DismissButton onClick={onDismiss}>Dismiss</DismissButton>

          <Button onClick={onConfirm}>Yes, delete it</Button>
        </PopoverModal.Actions>
      </PopoverModal.Content>
    </PopoverModal.Container>
  );
});
