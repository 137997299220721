import React, { ChangeEvent, useState } from 'react';

import { generateYears } from '../../../utils/date';
import { YearDropdown, YearFilterContainer } from './styles';

interface Props {
  loading: boolean;
  onChange: (year: number) => void;
}

const YearFilter: React.FC<Props> = (props: Props) => {
  const { loading, onChange } = props;
  const years = generateYears();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);

  const onChangeCb = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = Number(event.target.value);
    setSelectedYear(selectedYear);
    onChange(selectedYear);
  };

  return (
    <YearFilterContainer>
      <YearDropdown
        id="year"
        name="year"
        defaultValue={selectedYear}
        disabled={loading}
        onChange={onChangeCb}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </YearDropdown>
    </YearFilterContainer>
  );
};

export default YearFilter;
