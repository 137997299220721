import React, { useEffect } from 'react';

import {
  APPOINTMENT_TYPE_INPUT_OPTIONS,
  AppointmentTypes,
} from '../../../utils/constants';
import Radio from '../../atoms/radio';
import Section from '../../atoms/section';
import {
  AppointmentTypeInputContainer,
  AppointmentTypeOptionsContainer,
  ClearButton,
  ClearButtonContainer,
} from './styles';

interface Props {
  appointmentType?: string;
  isRemote?: boolean;
  onChange: (value: string) => void;
  onClear: () => void;
}

interface AppointmentTypeOptions {
  id: string;
  label: string;
  value: string;
}

const AppointmentTypeInput: React.FC<Props> = (props: Props) => {
  const { appointmentType, isRemote = false, onChange, onClear } = props;
  const isVideoAssistedSchedule =
    appointmentType === AppointmentTypes.VideoAssisted;

  useEffect(() => {
    if (!isRemote && !isVideoAssistedSchedule) return;
    onChange(AppointmentTypes.VideoAssisted);
  }, [appointmentType]);

  const onChangeCb = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    onChange(value);
  };

  return (
    <AppointmentTypeInputContainer>
      <ClearButtonContainer>
        <ClearButton onClick={onClear}>Clear Selection</ClearButton>
      </ClearButtonContainer>
      <AppointmentTypeOptionsContainer>
        {APPOINTMENT_TYPE_INPUT_OPTIONS.map(
          (option: AppointmentTypeOptions) => (
            <Section key={option.id} disableClick={option.id === 'no-doctor'}>
              <Radio
                id={option.id}
                label={option.label}
                value={option.value}
                checked={appointmentType === option.value}
                onChange={onChangeCb}
                disabled={option.id === 'no-doctor'}
              />
            </Section>
          ),
        )}
      </AppointmentTypeOptionsContainer>
    </AppointmentTypeInputContainer>
  );
};

export default AppointmentTypeInput;
