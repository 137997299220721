import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CreateSchedulePage from '../components/pages/create-schedule-page';
import EditSchedulePage from '../components/pages/edit-schedule-page';
import HomePage from '../components/pages/home-page';
import AuthHandler from './authHandler';

export function AppRoutes() {
  return (
    <AuthHandler>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          path="/create-schedule/exam-room/:examRoomIndex"
          component={CreateSchedulePage}
        />
        <Route path="/edit-appointment/:id" component={EditSchedulePage} />
      </Switch>
    </AuthHandler>
  );
}

export default AppRoutes;
