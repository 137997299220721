import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

export const YearFilterContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

export const YearDropdown = styled.select`
  display: flex;
  align-items: center;
  text-align: center;
  border: 2px solid ${colors.silver};
  background-color: white;
  border-radius: 10px;
  width: 150px;
  height: 35px;
  font-size: 100%;
  justify-content: space-between;
  color: ${colors.tundora};

  // Option styling
  option {
    background-color: ${colors.white};
    color: ${colors.tundora};
    font-weight: bold;
  
  }

  option:hover option:checked{
    background-color: ${colors.blueHover};
  }
`;
