import React from 'react';

import { ErrorMessageContainer, ErrorText } from './styles';

interface Props {
  text?: string;
}

const LoadingIndicator = (props: Props) => {
  const { text } = props;
  return (
    <ErrorMessageContainer>
      <ErrorText>{text || 'Something went wrong'}</ErrorText>
    </ErrorMessageContainer>
  );
};

export default LoadingIndicator;
