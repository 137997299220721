import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';
import { Size } from './types';

type Sizes = {
  [key in Size]: {
    width: string;
    height: string;
    border: string;
  };
};

interface Props {
  size: Size;
}

const sizes: Sizes = {
  xs: {
    width: '5px',
    height: '5px',
    border: `4px solid ${colors.silver}`,
  },
  sm: {
    width: '10px',
    height: '10px',
    border: `8px solid ${colors.silver}`,
  },
  md: {
    width: '20px',
    height: '20px',
    border: `10px solid ${colors.silver}`,
  },
  lg: {
    width: '40px',
    height: '40px',
    border: `15px solid ${colors.silver}`,
  },
};

const loading = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

export const Spinner = styled.div<Props>`
  display: flex;
  justify-content: center;

  &:after {
    content: '';
    width: ${({ size }) => sizes[size].width};
    height: ${({ size }) => sizes[size].height};
    border: ${({ size }) => sizes[size].border};
    border-top-color: ${colors.blue};
    border-radius: 50%;
    animation: ${loading} 1s ease infinite;
  }
`;
