import styled from '@emotion/styled';

export const Content = styled('div')`
  scroll-behavior: smooth;
  overflow-y: scroll;
  margin-top: 100px;
  height: calc(100% - 100px);
  width: 800px;
  justify-content: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

export default Content;
