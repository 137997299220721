import styled from '@emotion/styled';

import { colors } from '../../../../styles/colors';

export const ActionsContainer = styled.div`
  margin-top: auto;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  & button {
    width: 40%;
    height: 48px;
    transition: all 0.2s;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:nth-of-type(odd) {
      background-color: white;
      color: ${colors.tundora};

      border-color: ${colors.silverChalice};

      &:hover {
        background-color: ${colors.darkBlue};
        border: 1px solid ${colors.darkBlue};
        color: white;
      }
    }
  }
`;
