import { forwardRef, useCallback, useRef } from 'react';

import { Button } from '@warbyparker/retail-design-system';

import { useComposedRefs } from '../../../hooks/useComposedRefs';
import { ModalContainerRef, PopoverModal } from '../../atoms/popover-modal';

export const DeleteScheduleFailPopover = forwardRef<ModalContainerRef, any>(
  (_props, ref) => {
    const modalRef = useRef<ModalContainerRef>(null);
    const allModalRefs = useComposedRefs(ref, modalRef);

    const onDismiss = useCallback(() => {
      modalRef.current?.closeModal();
    }, [modalRef]);

    return (
      <PopoverModal.Container ref={allModalRefs}>
        <PopoverModal.Content>
          <PopoverModal.Title>Failed to delete schedule</PopoverModal.Title>

          <PopoverModal.Description>
            Something wrong happened
          </PopoverModal.Description>

          <PopoverModal.Actions>
            <Button onClick={onDismiss}>Close</Button>
          </PopoverModal.Actions>
        </PopoverModal.Content>
      </PopoverModal.Container>
    );
  },
);
