import React, { InputHTMLAttributes } from 'react';

import { SearchIcon, InformationIcon } from '@warbyparker/retail-design-system';

import LoadingSpinner from '../spinner';
import {
  Input as StyledInput,
  SpinnerContainer,
  ErrorContainer,
  IconContainer,
} from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  loading: boolean;
  error: boolean;
}
const SearchableInput: React.FC<Props> = (props: Props) => {
  const { loading, error, ...rest } = props;
  return (
    <React.Fragment>
      <StyledInput isSearchable {...rest} />
      <IconContainer>
        <SearchIcon />
      </IconContainer>
      {error && (
        <ErrorContainer>
          <InformationIcon color={'destructive'} />
        </ErrorContainer>
      )}
      {loading && (
        <SpinnerContainer>
          <LoadingSpinner size="xs" />
        </SpinnerContainer>
      )}
    </React.Fragment>
  );
};

export default SearchableInput;
