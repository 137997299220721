import { DoctorsData } from '../../types/doctors';
import { SchedulestData } from '../../types/schedules';
import {
  SCHEDULES_FAILURE,
  SCHEDULES_REQUEST,
  SCHEDULES_SET_SCHEDULES_TYPE,
  SCHEDULES_SET_DOCTOR,
  SCHEDULES_SET_END_AT_TIME,
  SCHEDULES_SET_START_AT_TIME,
  SCHEDULES_SUCCESS,
  SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES,
  SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES,
  SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES,
  SchedulestFailure,
  SchedulestRequest,
  SchedulestSetSchedulestType,
  ScheduleSetAppointmentTypesAdultServices,
  ScheduleSetAppointmentTypesMinorServices,
  ScheduleSetAppointmentTypesOtherServices,
  SchedulestSetDoctor,
  SchedulestSetEndAtTime,
  SchedulestSetStartAtTime,
  SchedulestSuccess,
  EDIT_SCHEDULES_FAILURE,
  EDIT_SCHEDULES_SUCCESS,
  EDIT_SCHEDULES_REQUEST,
  EditSchedulestRequest,
  EditSchedulestSuccess,
  EditSchedulestFailure,
} from './actions';

export function startSchedulestRequest(): SchedulestRequest {
  return {
    type: SCHEDULES_REQUEST,
  };
}

export function setSchedulestSuccess(
  payload: SchedulestData,
): SchedulestSuccess {
  return {
    type: SCHEDULES_SUCCESS,
    payload,
  };
}

export function setSchedulestFailure(): SchedulestFailure {
  return {
    type: SCHEDULES_FAILURE,
  };
}

export function setDoctorSchedulest(
  payload?: DoctorsData,
): SchedulestSetDoctor {
  return {
    type: SCHEDULES_SET_DOCTOR,
    payload,
  };
}

export function setStartAtTime(payload: string): SchedulestSetStartAtTime {
  return {
    type: SCHEDULES_SET_START_AT_TIME,
    payload,
  };
}

export function setEndAtTime(payload: string): SchedulestSetEndAtTime {
  return {
    type: SCHEDULES_SET_END_AT_TIME,
    payload,
  };
}

export function setSchedulestType(
  payload: string,
): SchedulestSetSchedulestType {
  return {
    type: SCHEDULES_SET_SCHEDULES_TYPE,
    payload,
  };
}

export function setAppointmentTypesAdultServices(payload: string[]): ScheduleSetAppointmentTypesAdultServices {
  return {
    type: SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES,
    payload,
  };
}

export function setAppointmentTypesMinorServices(payload: string[]): ScheduleSetAppointmentTypesMinorServices {
  return {
    type: SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES,
    payload,
  };
}

export function setAppointmentTypesOtherServices(payload: string[]): ScheduleSetAppointmentTypesOtherServices {
  return {
    type: SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES,
    payload,
  };
}

export function starEditSchedulestRequest(): EditSchedulestRequest {
  return {
    type: EDIT_SCHEDULES_REQUEST,
  };
}

export function setEditSchedulestSuccess(): EditSchedulestSuccess {
  return {
    type: EDIT_SCHEDULES_SUCCESS,
  };
}

export function setEditSchedulestFailure(): EditSchedulestFailure {
  return {
    type: EDIT_SCHEDULES_FAILURE,
  };
}
