import { format, parseISO } from 'date-fns';
const PAST_YEAR = 2010;
const DEFAULT_TIME = '00:00';

export function formatDateWithSuffix(startAt: string): string {
  const date: Date = parseISO(startAt);
  const formattedDate = format(date, 'EEEE, MMMM do');
  return `${formattedDate}`;
}

export function getTimeFromScheduleDate(date: string[]) {
  if (date.length <= 1) return DEFAULT_TIME;

  const time = date[1];
  return time.substring(0, date[1].length - 4);
}

export function splitScheduleTime(startAt: string, endAt: string): string {
  const startSplitArray = startAt.split('T');
  const endSplitArray = endAt.split('T');

  const startSplitSchedule = getTimeFromScheduleDate(startSplitArray);
  const endSplitSchedule = getTimeFromScheduleDate(endSplitArray);

  return `${startSplitSchedule} - ${endSplitSchedule}`;
}

export function generateYears(): number[] {
  const pastYear = PAST_YEAR;
  const currentYear = new Date().getFullYear();
  const length = currentYear - pastYear + 1;
  return Array.from({ length }, (_, index) => pastYear + index);
}

export function getISO8601DateTime(date: string, time: string): string {
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes] = time.split(':').map(Number);

  const datetime = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  return datetime.toISOString();
}

