import styled from '@emotion/styled';
import { colors } from '../../../styles/colors';

interface IDropdownContainer {
  error: boolean;
  open: boolean;
  count: number;
}

export const Label = styled('label')`
  color: ${colors.smoke};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-grid;
`;

export const DropdownMenu = styled.ul<IDropdownContainer>`
  display: ${({ open, error }) => (open && !error ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: ${colors.silk};
  border: 1px solid ${colors.silver};
  border-radius: 5px;
  width: 100%;
  overflow: scroll;
  max-height: ${({ count }) => (!count ? 35 : count * 35)}px;
`;

export const DropdownMenuItem = styled.li`
  padding: 8px;
  cursor: pointer;
  color: ${colors.stone};
  font-size: 15px;

  &:hover {
    background-color: ${colors.silver};
  }
`;
