import { Schedules } from '../../types/schedules';

export interface State {
  schedules: Schedules | undefined;
  loading: boolean;
  error: boolean;
  currentMonth: number;
  currentYear: number;
}

export const initialState: State = {
  schedules: undefined,
  loading: false,
  error: false,
  currentMonth: new Date().getMonth() + 1,
  currentYear: new Date().getFullYear(),
};
