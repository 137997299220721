import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

export const MonthFilterContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${colors.silver};
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  width: 150px;
  justify-content: space-between;
`;

export const MonthLabel = styled.label`
  margin: 0 10px;
  color: ${colors.tundora};
`;

export const ArrowButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.charcoal};
  margin-top: 2px;
`;
