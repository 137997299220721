import React from 'react';
import { ScheduleData } from '../../../types/schedules';
import { TableCell } from '../../atoms/table';
import { DoctorsList } from './styles';

interface SummarizedContentProps {
  schedulesByExamRoom: ScheduleData[];
  toggleCollapse: () => void;
}

const SummarizedContent: React.FC<SummarizedContentProps> = ({ schedulesByExamRoom, toggleCollapse }) => {
  const [areDoctorsExpanded, setDoctorsExpanded] = React.useState(false);

  const toggleDoctorsList = () => {
    setDoctorsExpanded(!areDoctorsExpanded);
  };

  if (!schedulesByExamRoom.length) {
    return <TableCell onClick={toggleCollapse} columnIndex={1}>No Coverage</TableCell>;
  }

  const numAppointments = schedulesByExamRoom.length;
  const keyDoctors = schedulesByExamRoom.slice(0, 2).map(schedule => `${schedule.doctor.firstName} ${schedule.doctor.lastName}`);
  const isAllRemote = schedulesByExamRoom.every(schedule => schedule.doctor.isRemote);
  const appointmentTypeText = isAllRemote ? 'All Remote' : `${new Set(schedulesByExamRoom.flatMap(s => s.appointmentTypes)).size} types`;

  return (
    <TableCell onClick={toggleCollapse}>
      {numAppointments} Schedules<br />
      Doctors: 
      {areDoctorsExpanded 
        ? schedulesByExamRoom.map(schedule => `${schedule.doctor.firstName} ${schedule.doctor.lastName}`).join(", ")
        : keyDoctors.join(", ")
      }
      {schedulesByExamRoom.length > 2 && 
        <DoctorsList onClick={(e) => {
          e.stopPropagation();
          toggleDoctorsList();
        }}>
          {areDoctorsExpanded ? " Less" : `+ ${schedulesByExamRoom.length - 2} more`}
        </DoctorsList>
      }
      <br />
      {appointmentTypeText}
    </TableCell>
  );
};

export default SummarizedContent;
