export interface State {
  disabled: boolean;
  facilityName: string | null;
  facilityShortName: string | null;
}

export const initialState: State = {
  disabled: false,
  facilityName: null,
  facilityShortName: null,
};
