import environments from '../../data-access/environments';
import { HttpClient, RequestOptions } from '../../types/common';
import { handleResponse, handleUnauthorized } from './error';

export function createHttpClient(
  accessToken: string,
  logout?: () => void,
): HttpClient {
  return async <T>(
    requestInfo: RequestInfo,
    options: RequestOptions<T> = {},
  ) => {
    const { noAuth = false } = options;

    const request = getRequest(requestInfo, accessToken, noAuth);

    const modifyResponse =
      options?.responseModifier ??
      (async (response: Response) => {
        return await response.json();
      });

    return await fetch(request)
      .then(handleUnauthorized(logout || onLogout))
      .then(handleResponse)
      .then(modifyResponse);
  };
}

function getRequest(
  requestInfo: RequestInfo,
  accessToken: string,
  noAuth: boolean,
): Request {
  if (noAuth) {
    return new Request(requestInfo);
  }

  const request = new Request(requestInfo);
  request.headers.set('Authorization', `Bearer ${accessToken}`);
  return request;
}

function onLogout() {
  window.location.replace(
    `${environments.apiUrl}/login?next=${window.location.href}`,
  );
}
