import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSchedules } from '../../../data-access/schedules/use-schedules';
import { useLocalState as useHeaderState } from '../../../store/header-component/StateProvider';
import {
  setSchedulesFailure,
  setSchedulesSuccess,
  setMonth,
  setYear,
  startSchedulesRequest,
} from '../../../store/home-page/action-creators';
import {
  useLocalDispatch,
  useLocalState,
} from '../../../store/home-page/StateProvider';
import { setOffice } from '../../../store/user-profile/action-creators';
import {
  useUserProfile,
  useDispatchUserProfile,
} from '../../../store/user-profile/StateProvider';
import { GetSchedulesQuery } from '../../../types/common';
import { GroupedSchedule, Schedules } from '../../../types/schedules';
import LoadingSpinner from '../../atoms/spinner';
import MonthFilter from '../../molecules/month-filter';
import YearFilter from '../../molecules/year-filter';
import SchedulesTable from '../schedules-table';
import { CenteredContainer, FilterContainer } from './styles';
import { filterOutScheduleOfId, groupSchedulesByStartDate } from './util';

const ExamRooms: React.FC = () => {
  const { userProfile } = useUserProfile();
  const { loading, currentMonth, currentYear } = useLocalState();
  const { facilityShortName } = useHeaderState();
  const dispatch = useLocalDispatch();
  const userProfileDispatch = useDispatchUserProfile();
  const getSchedules = useSchedules();
  const { search } = useLocation();

  const excludeIds = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const ids = searchParams.get('omitIds');

    return ids !== null ? ids.split(',') : [];
  }, [search]);

  const [schedulesData, setSchedulesData] = useState<GroupedSchedule>({});

  useEffect(() => {
    const { facility } = userProfile;

    if (!facility) return;

    const { short_name: defaultFacilityShortName } = facility;
    const shortNameFilter = !facilityShortName
      ? defaultFacilityShortName
      : facilityShortName;
    const query: GetSchedulesQuery = {
      shortName: shortNameFilter,
      date: `${currentYear}/${currentMonth}`,
    };
    onSchedulesRequest(query);
  }, [currentMonth, currentYear, facilityShortName]);

  const onSchedulesRequest = (query: GetSchedulesQuery) => {
    dispatch(startSchedulesRequest());
    getSchedules(query).then(onSchedulesSuccess).catch(onSchedulesFailure);
  };

  const onSchedulesSuccess = (response: Schedules) => {
    const { data } = response;

    if (!data.length) {
      dispatch(setSchedulesSuccess(response));
      setSchedulesData({});
      return;
    }

    const appointmentWithOffice = data.find(item => !!item && item.officeId);

    if (!appointmentWithOffice) {
      dispatch(setSchedulesFailure());
      setSchedulesData({});
      return;
    }

    const { officeId } = appointmentWithOffice;
    userProfileDispatch(setOffice(officeId));
    dispatch(setSchedulesSuccess(response));

    const schedules = groupSchedulesByStartDate(
      filterOutScheduleOfId(response, ...excludeIds),
    );

    setSchedulesData(schedules);
  };

  const onSchedulesFailure = () => {
    dispatch(setSchedulesFailure());
    setSchedulesData({});
  };

  const onNextMonth = (month: number) => {
    dispatch(setMonth(month));
  };

  const onPreviousMonth = (month: number) => {
    dispatch(setMonth(month));
  };

  const onSelectYear = (year: number) => {
    dispatch(setYear(year));
  };

  return (
    <CenteredContainer>
      <FilterContainer>
        <MonthFilter
          loading={loading}
          onPreviousMonth={onPreviousMonth}
          onNextMonth={onNextMonth}
        />
        <YearFilter loading={loading} onChange={onSelectYear} />
      </FilterContainer>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <SchedulesTable data={schedulesData || {}} />
      )}
    </CenteredContainer>
  );
};

export default ExamRooms;
