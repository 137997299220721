import { DoctorsData } from '../../../types/doctors';
import { SchedulestData } from '../../../types/schedules';
import { CreateScheduleRequestDTO } from '../../../types/schedules/dto';
import { Facility } from '../../../types/userProfile';
import { appointmentTypesMapper } from '../../../utils/constants';
import { formatDateWithSuffix, getISO8601DateTime } from '../../../utils/date';

export function getCreateScheduleSubtitle(
  start: string | null,
  examRoomIndex: string,
): string {
  if (!start) {
    return `(Exam Room ${examRoomIndex})`;
  }

  return `${formatDateWithSuffix(start)} (Exam Room ${examRoomIndex})`;
}

export function getEditScheduleSubtitle(schedule?: SchedulestData) {
  if (!schedule) return 'No data';

  const { start, examRoomIndex } = schedule;

  return `${formatDateWithSuffix(start)} (Exam Room ${examRoomIndex})`;
}

export function buildAndValidateSchedule(data: {
  office?: string | null;
  scheduleDate: string | null;
  startAt?: string;
  endAt?: string;
  doctor?: DoctorsData;
  examRoomIndex?: string | number;
  appointmentType?: string;
  appointmentTypes?: string[];
  adultServices?: string[],
  minorServices?: string[],
  otherServices?: string[],
}): CreateScheduleRequestDTO | undefined {
  const {
    startAt,
    endAt,
    office,
    doctor,
    examRoomIndex,
    scheduleDate,
    appointmentType,
    adultServices,
    minorServices,
    otherServices,
  } = data;
  if (!startAt || !endAt || !doctor?.id || !office || !scheduleDate || !appointmentType) {
    return undefined;
  }
  const timeRegex = /^(\d{2}):(\d{2})$/; // to check if time is format "12:00"

  const start = timeRegex.test(startAt)
    ? getISO8601DateTime(scheduleDate, startAt)
    : startAt;
  const end = timeRegex.test(endAt)
    ? getISO8601DateTime(scheduleDate, endAt)
    : endAt;
  
  let appointmentTypes: string[] = [];

  if (adultServices) {
    appointmentTypes = [...appointmentTypes, ...adultServices];
  }

  if (minorServices) {
    appointmentTypes = [...appointmentTypes, ...minorServices];
  }

  if (otherServices) {
    appointmentTypes = [...appointmentTypes, ...otherServices];
  }

  appointmentTypes.push(appointmentType);

  return {
    appointmentTypes: appointmentTypes,
    examRoomIndex: Number(examRoomIndex),
    start,
    end,
    doctorId: doctor.id,
    officeId: office
  };
}

function determineSchedulestTypes(appointmentType: string): string[] {
  return appointmentTypesMapper.reduce((acc: string[], apptType) => {
    if (apptType.appointmentType === appointmentType) {
      acc.push(apptType.configuration);
    }

    return acc;
  }, []);
}
