import { ActionsContainer } from './styles';

interface ModalActionsProps extends React.HTMLAttributes<HTMLHeadingElement> {}

export const ModalActions: React.FC<ModalActionsProps> = ({
  children,
  ...props
}) => {
  return <ActionsContainer {...props}>{children}</ActionsContainer>;
};
