import styled from '@emotion/styled';

const ButtonContainer = styled.div`
  width: 35px;
  display: flex;
  justify-content: initial;
  gap: 12px;
`;

export default ButtonContainer;
