export const SCHEDULES_CHANGE_DISABLED = 'SCHEDULES_CHANGE_DISABLED';
export const SCHEDULES_CHANGE_NAME = 'SCHEDULES_CHANGE_NAME';
export const SCHEDULES_CHANGE_SHORT_NAME = 'SCHEDULES_CHANGE_SHORT_NAME';

export interface SchedulesChangeDisabled {
  type: typeof SCHEDULES_CHANGE_DISABLED;
  payload: boolean;
}

export interface SchedulesChangeFacilityName {
  type: typeof SCHEDULES_CHANGE_NAME;
  payload: string;
}

export interface SchedulesChangeShortName {
  type: typeof SCHEDULES_CHANGE_SHORT_NAME;
  payload: string;
}

export type Action =
  | SchedulesChangeDisabled
  | SchedulesChangeFacilityName
  | SchedulesChangeShortName;
