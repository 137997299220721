import { ReactElement } from 'react';

import { HttpClientContext } from '../../contexts/http-client';
import { useAuth } from '../../store/auth/StateProvider';
import { createHttpClient } from '../../utils/data-access/createHttpClient';

interface Props {
  children: ReactElement;
}

export function HttpClientProvider(props: Props) {
  const { children } = props;
  const { accessToken } = useAuth();
  const httpClient = createHttpClient(accessToken || '');

  return (
    <HttpClientContext.Provider value={httpClient}>
      {children}
    </HttpClientContext.Provider>
  );
}

export default HttpClientProvider;
