import { AppointmentTypes } from '../../types/schedules';

export interface State {
  facilityServices: AppointmentTypes;
  error: boolean;
  loading: boolean;
}

const facilityServicesDefaultValue = {
  availableServices: [],
  defaultOptions: [],
};

export const initialState: State = {
  facilityServices: facilityServicesDefaultValue,
  loading: false,
  error: false,
};
