import { UserProfile } from '../../types/userProfile';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';
export const USER_PROFILE_SET_OFFICE = 'USER_PROFILE_SET_OFFICE';

export interface UserProfileRequest {
  type: typeof USER_PROFILE_REQUEST;
}

export interface UserProfileSuccess {
  type: typeof USER_PROFILE_SUCCESS;
  payload: UserProfile;
}

export interface UserProfileFailure {
  type: typeof USER_PROFILE_FAILURE;
}

export interface UserProfileSetOffice {
  type: typeof USER_PROFILE_SET_OFFICE;
  payload: string;
}

export type Action =
  | UserProfileRequest
  | UserProfileSuccess
  | UserProfileFailure
  | UserProfileSetOffice;
