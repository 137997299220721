import React from 'react';

import { DoctorsData } from '../../../types/doctors';
import Input from '../../atoms/input';
import { DropdownContainer, DropdownMenu, DropdownMenuItem } from './styles';

export interface Props {
  open: boolean;
  error: boolean;
  options: DoctorsData[];
  loading: boolean;
  onClick: (doctor: DoctorsData) => void;
  onInitDoctorSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DoctorSearchInput: React.FC<Props> = (props: Props) => {
  const { open, error, options, loading, onInitDoctorSearch, onClick } = props;

  return (
    <DropdownContainer>
      <Input.Search
        type="text"
        id="doctor"
        name="doctor"
        loading={loading}
        error={error}
        onFocus={onInitDoctorSearch}
        onChange={onInitDoctorSearch}
      />
      <DropdownMenu open={open} error={error} count={options.length}>
        {!options || !options.length ? (
          <DropdownMenuItem> No results </DropdownMenuItem>
        ) : (
          options.map(doctor => (
            <DropdownMenuItem key={doctor.id} onClick={() => onClick(doctor)}>
              {doctor.firstName} {doctor.lastName}
            </DropdownMenuItem>
          ))
        )}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default DoctorSearchInput;
