import React from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowLeft24Icon } from '@warbyparker/retail-design-system';

import Button from '../../atoms/button';
import IconContainer from './styles';

export const BackButtonIcon = (
  <IconContainer>
    <ArrowLeft24Icon />
  </IconContainer>
);

interface Props {
  homeUrl?: string;
}

const HeaderBackButton: React.FC<Props> = ({ homeUrl }) => {
  const history = useHistory();

  const onBack = () => {
    if (homeUrl) {
      window.location.replace(homeUrl);
    } else {
      history.goBack();
    }
  };

  return <Button onClick={onBack} icon={BackButtonIcon} />;
};

export default HeaderBackButton;
