import { useHttpClient } from '../../contexts/http-client';
import Token from '../../types/auth';
import environments from '../environments';

export const useAccessToken = () => {
  const httpClient = useHttpClient();
  return async () => {
    const url = `${environments.apiUrl}/api/v1/user/jwt`;
    const request = new Request(url, {
      credentials: 'include',
    });

    return await httpClient<Token>(request, {
      responseModifier: async res => {
        return await res.json();
      },
      noAuth: true,
    });
  };
};
