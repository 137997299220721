import React from 'react';

import styled from '@emotion/styled';

import { getTimeFromScheduleDate } from '../../../utils/date';
import { colors } from '../../../styles/colors';
import Input from '../../atoms/input';
import Section from '../../atoms/section';

const Label = styled('label')`
  color: ${colors.smoke};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 6px;
`;

interface Props {
  startAt?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StartTimeInput: React.FC<Props> = (props: Props) => {
  const { startAt, onChange } = props;
  const separatedDate = startAt ? startAt.split('T') : [];
  const startTimeFormatted = getTimeFromScheduleDate(separatedDate);

  return (
    <Section>
      <Label htmlFor="start-time">Start Time</Label>
      <Input.Text
        type="time"
        id="start-time"
        name="start-time"
        defaultValue={startTimeFormatted}
        onChange={onChange}
      />
    </Section>
  );
};

export default StartTimeInput;
