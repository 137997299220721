import { Schedules } from '../../types/schedules';
import {
  SCHEDULES_CHANGE_MONTH,
  SCHEDULES_CHANGE_YEAR,
  SCHEDULES_FAILURE,
  SCHEDULES_REQUEST,
  SCHEDULES_SUCCESS,
  SchedulesChangeMonth,
  SchedulesChangeYear,
  SchedulesFailure,
  SchedulesRequest,
  SchedulesSuccess,
} from './actions';

export function startSchedulesRequest(): SchedulesRequest {
  return {
    type: SCHEDULES_REQUEST,
  };
}

export function setSchedulesSuccess(payload: Schedules): SchedulesSuccess {
  return {
    type: SCHEDULES_SUCCESS,
    payload,
  };
}

export function setSchedulesFailure(): SchedulesFailure {
  return {
    type: SCHEDULES_FAILURE,
  };
}

export function setMonth(payload: number): SchedulesChangeMonth {
  return {
    type: SCHEDULES_CHANGE_MONTH,
    payload,
  };
}

export function setYear(payload: number): SchedulesChangeYear {
  return {
    type: SCHEDULES_CHANGE_YEAR,
    payload,
  };
}
