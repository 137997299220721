import { LoadingContainer, LoadingText } from './styles';

interface Props {
  text?: string;
}

const LoadingIndicator = (props: Props) => {
  const { text } = props;
  return (
    <LoadingContainer>
      <LoadingText>{text || 'Loading...'}</LoadingText>
    </LoadingContainer>
  );
};

export default LoadingIndicator;
