export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export interface AuthRequest {
  type: typeof AUTH_REQUEST;
}

export interface AuthSuccess {
  type: typeof AUTH_SUCCESS;
  payload: string;
}

export interface AuthFailure {
  type: typeof AUTH_FAILURE;
}

export type Action = AuthRequest | AuthSuccess | AuthFailure;
