import { Reducer } from 'react';

import { produce } from 'immer';

import { Action } from './actions';
import { State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'SCHEDULES_REQUEST':
      draft.loading = true;
      draft.error = false;
      break;
    case 'SCHEDULES_SUCCESS':
      draft.loading = false;
      draft.error = false;
      draft.schedule = action.payload;
      draft.form.doctor = action.payload.doctor;
      draft.form.startAt = action.payload.start;
      draft.form.endAt = action.payload.end;
      draft.form.appointmentTypes = action.payload.appointmentTypes;
      break;
    case 'SCHEDULES_FAILURE':
      draft.loading = false;
      draft.error = true;
      draft.schedule = undefined;
      break;
    case 'SCHEDULES_SET_DOCTOR':
      draft.form.doctor = action.payload;
      break;
    case 'SCHEDULES_SET_START_AT_TIME':
      draft.form.startAt = action.payload;
      break;
    case 'SCHEDULES_SET_END_AT_TIME':
      draft.form.endAt = action.payload;
      break;
    case 'SCHEDULES_SET_SCHEDULES_TYPE':
      draft.form.appointmentType = action.payload;
      break;
    case 'EDIT_SCHEDULES_REQUEST':
      draft.loading = true;
      draft.error = false;
      break;
    case 'EDIT_SCHEDULES_SUCCESS':
      draft.loading = false;
      draft.error = false;
      break;
    case 'EDIT_SCHEDULES_FAILURE':
      draft.loading = false;
      draft.error = true;
      break;
  }
});

export default reducer;
