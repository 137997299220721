import React from 'react';
import { HiddenCheckbox, CheckboxContainer } from './styles';

interface CheckboxProps {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CheckboxComponent: React.FC<CheckboxProps> = ({ checked, onChange, disabled }) => {
    return (
        <CheckboxContainer>
            <HiddenCheckbox type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        </CheckboxContainer>
    );
}

export default CheckboxComponent;
