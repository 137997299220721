import { useCallback } from 'react';

type PossibleRef<T> = React.Ref<T> | undefined;
type ComposedRefs<T> = (node: T) => void;

type UseComposedRefsFn = <T>(...refs: Array<PossibleRef<T>>) => ComposedRefs<T>;

const setRef = <T>(ref: PossibleRef<T>, value: T): void => {
  if (ref === null || ref === undefined) return;

  if (typeof ref === 'function') {
    ref(value);

    return;
  }

  (ref as React.MutableRefObject<T>).current = value;
};

export const composeRefs = <T>(
  ...refs: Array<PossibleRef<T>>
): ComposedRefs<T> => {
  return (node: T) => refs.forEach(ref => setRef(ref, node));
};

export const useComposedRefs: UseComposedRefsFn = (...refs) => {
  return useCallback(composeRefs(...refs), refs);
};
