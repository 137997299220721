import { useHttpClient } from '../../contexts/http-client';
import { GetSchedulesQuery } from '../../types/common';
import { Schedules } from '../../types/schedules';
import environments from '../environments';

export const useSchedules = () => {
  const httpClient = useHttpClient();

  return async (query: GetSchedulesQuery) => {
    const { apiUrl } = environments;
    const { shortName, date } = query;
    const url = `${apiUrl}/api/v1/doctor-schedules/list-by-facility?facility_short_name=${shortName}&date=${date}`;
    const request = new Request(url);

    return await httpClient<Schedules>(request, {
      responseModifier: async res => {
        return await res.json();
      },
    });
  };
};
