import React from 'react';
import { useHistory } from 'react-router-dom';

import { ScheduleData } from '../../../types/schedules';
import { TableCell } from '../../atoms/table';
import { getExamRoomDisplayText, getNoCoverageText } from './util';

interface Props {
  date: string;
  examRoomIndex: number;
  schedule?: ScheduleData;  
}

export const ExamRoomCell: React.FC<Props> = ({ date, examRoomIndex, schedule }) => {
  const history = useHistory();

  const onClickExamRoom = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    const to = !schedule
      ? `create-schedule/exam-room/${examRoomIndex}?scheduleDate=${date}`
      : `edit-appointment/${schedule.id}`;
    history.push(to);
  };

  return (
    <TableCell columnIndex={examRoomIndex}>
      {schedule ? (
        <div key={schedule.id} onClick={onClickExamRoom}>
          {getExamRoomDisplayText(schedule)}
        </div>
      ) : (
        <div onClick={onClickExamRoom}>
          {getNoCoverageText()}
        </div>
      )}
    </TableCell>
  );
};

export default ExamRoomCell;
