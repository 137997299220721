import React from 'react';

import StateProvider from '../../../store/edit-appointment-page/StateProvider';
import EditSchedulePageRoot from './edit-schedule-page-root';

const EditSchedulePage: React.FC = () => {
  return (
    <StateProvider>
      <EditSchedulePageRoot />
    </StateProvider>
  );
};

export default EditSchedulePage;
