import React from 'react';

import { useFacilityServices } from '../../../store/facility-services/StateProvider';
import { DefaultConfig, DefaultOption } from '../../../types/schedules';
import { AppointmentTypes } from '../../../utils/constants';
import { TableContainer, Table, TableHeader, FacilityTableHeader, FacilityTableContainer } from '../../atoms/table';
import AdultServicesSection from '../../molecules/adult-services-section';
import MinorServicesSection from '../../molecules/minor-services-section';
import OtherServicesSection from '../../molecules/other-services-section';
import { TableWrapper } from './styles';
import { getAvailableServicesWithName, getFilteredOptions, processCheckedServices } from './util';

interface Props {
  appointmentType?: string;
  onServicesChange: (updatedCheckedServices: { [key: string]: boolean }) => void;
}

const FacilityServicesTable: React.FC<Props> = ({ appointmentType, onServicesChange }) => {
  const { facilityServices } = useFacilityServices();
  const isRemote = appointmentType === AppointmentTypes.VideoAssisted;
  const services = getAvailableServicesWithName(facilityServices, isRemote);
  const {
    adultApptTypesWithNames,
    minorApptTypesWithNames,
    otherApptTypesWithNames,
  } = services;

  const handleServicesChange = (
    newCheckedServices: { [key: string]: boolean },
    appTypesWithNames: DefaultOption[],
    servicesType?: string
  ) => {
    const { updatedCheckedServices, hasTrueValue } = processCheckedServices(
      newCheckedServices,
      appTypesWithNames
    );
  
    if (servicesType && hasTrueValue) {
      updatedCheckedServices[servicesType] = true;
    }
    onServicesChange(updatedCheckedServices);
  };

  return (
    <FacilityTableContainer>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <TableHeader>Appointment Type</TableHeader>
              <TableHeader>Available</TableHeader>
            </tr>
          </thead>
          <tbody>
            <AdultServicesSection 
              services={adultApptTypesWithNames} 
              onCheckedServicesChange={(newCheckedServices) =>
                handleServicesChange(newCheckedServices, adultApptTypesWithNames, 'ADULT')
              }
            />
            <MinorServicesSection 
              services={minorApptTypesWithNames}
              onCheckedServicesChange={(newCheckedServices) =>
                handleServicesChange(newCheckedServices, minorApptTypesWithNames, 'MINOR')
              }
            />
            <OtherServicesSection
              services={otherApptTypesWithNames} 
              onCheckedServicesChange={(newCheckedServices) =>
                handleServicesChange(newCheckedServices, otherApptTypesWithNames)
              }
            />
          </tbody>
        </Table>
      </TableWrapper>
    </FacilityTableContainer>
  );
};

export default FacilityServicesTable;
