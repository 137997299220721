import { AppointmentTypes } from '../../types/schedules';
import { CreateScheduleForm } from '../../types/schedules/forms';

export interface State {
  loading: boolean;
  error: boolean;
  form: CreateScheduleForm;
  appointmentTypesByFacility: AppointmentTypes;
}

export const initialState: State = {
  loading: false,
  error: false,
  form: {},
  appointmentTypesByFacility: {
    availableServices: [],
    defaultOptions: [],
  },
};
