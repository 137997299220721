import React from 'react';

import Content from '../../atoms/content';
import Page from '../../atoms/page';
import ExamRooms from '../../organisms/exam-rooms';
import Header from '../../organisms/header';

const HomePageRoot = () => {
  const poeUrl = `https://${process.env.POE_DOMAIN}`;

  return (
    <React.Fragment>
      <Page>
        <Header homeUrl={poeUrl} />
        <Content>
          <ExamRooms />
        </Content>
      </Page>
    </React.Fragment>
  );
};

export default HomePageRoot;
