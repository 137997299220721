import React, { ReactNode, useEffect } from 'react';

import ErrorPage from '../components/pages/error-page';
import LoadingIndicatorPage from '../components/pages/loading-indicator-page';
import { useAppointmentTypes } from '../data-access/appointment-types/use-appointment-types';
import { useAccessToken } from '../data-access/auth/useAccessToken';
import { useUser } from '../data-access/user/useUser';
import {
  setAuthenticationFailure,
  setAuthenticationSuccess,
  startAuthenticationRequest,
} from '../store/auth/action-creators';
import { useDispatchAuth, useAuth } from '../store/auth/StateProvider';
import {
  setFacilityServicesFailure,
  setFacilityServicesSuccess,
  startFacilityServicesRequest,
} from '../store/facility-services/actions-creators';
import {
  useDispatchFacilityServices,
  useFacilityServices,
} from '../store/facility-services/StateProvider';
import {
  setUserProfileFailure,
  setUserProfileSuccess,
  startUserProfileRequest,
} from '../store/user-profile/action-creators';
import {
  useDispatchUserProfile,
  useUserProfile,
} from '../store/user-profile/StateProvider';
import Token from '../types/auth';
import { AppointmentTypes } from '../types/schedules';
import { UserProfile } from '../types/userProfile';

interface Props {
  children: ReactNode;
}

function AuthenticationHandler(props: Props) {
  const { isAuthenticated, error } = useAuth();
  const { loading: loadingUserProfile, userProfile } = useUserProfile();
  const { loading: loadingFacilityServices } = useFacilityServices();
  const getAccessToken = useAccessToken();
  const getUserInfo = useUser();
  const getAppointmentTypes = useAppointmentTypes();
  const dispatch = useDispatchAuth();
  const dispatchUserProfile = useDispatchUserProfile();
  const dispatchFacilityServices = useDispatchFacilityServices();

  useEffect(() => {
    const { user } = userProfile;

    if (!user) return;

    onFacilityServicesRequest();
  }, [userProfile]);

  useEffect(() => {
    if (!isAuthenticated) return;

    onUserInfoRequest();
  }, [isAuthenticated]);

  useEffect(() => {
    onLogin();
  }, []);

  const onLogin = () => {
    startAuthenticationRequest();
    getAccessToken().then(onSuccess).catch(onFailure);
  };

  const onSuccess = (response: Token) => {
    const { token } = response;
    dispatch(setAuthenticationSuccess(token));
  };

  const onFailure = () => {
    dispatch(setAuthenticationFailure());
  };

  const onUserInfoRequest = () => {
    dispatchUserProfile(startUserProfileRequest());
    getUserInfo().then(onUserInfoSuccess).catch(onUserInfoFailure);
  };

  const onUserInfoSuccess = (response: UserProfile) => {
    dispatchUserProfile(setUserProfileSuccess(response));
  };

  const onUserInfoFailure = () => {
    dispatchUserProfile(setUserProfileFailure());
  };

  const onFacilityServicesRequest = () => {
    const { facility } = userProfile;

    if (!facility) {
      dispatchFacilityServices(
        setFacilityServicesSuccess({
          availableServices: [],
          defaultOptions: [],
        }),
      );
      return;
    }

    const { short_name: defaultFacility } = facility;
    dispatchFacilityServices(startFacilityServicesRequest());
    getAppointmentTypes(defaultFacility)
      .then(onFacilityServicesSuccess)
      .catch(onFacilityServicesFailure);
  };

  const onFacilityServicesSuccess = (response: AppointmentTypes) => {
    dispatchFacilityServices(setFacilityServicesSuccess(response));
  };

  const onFacilityServicesFailure = () => {
    dispatchFacilityServices(setFacilityServicesFailure());
  };

  if (isAuthenticated && userProfile.user) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  if (error) {
    return <ErrorPage text={'Redirecting...'} />;
  }

  return loadingUserProfile || loadingFacilityServices ? (
    <LoadingIndicatorPage />
  ) : null;
}

export default AuthenticationHandler;
