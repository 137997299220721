import { createContext, Dispatch } from 'react';

import { Action } from './actions';
import { initialState, State } from './state';

interface Value {
  state: State;
  dispatch: Dispatch<Action>;
}

const defaultValue: Value = {
  state: initialState,
  dispatch: () => null,
};

const Context = createContext(defaultValue);

export default Context;
