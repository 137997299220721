import {
  AppointmentTypes,
  AvailableService,
  DefaultOption,
} from '../../../types/schedules';
import {
  ScheduleConfigurations,
  ServicesProfiles,
} from '../../../utils/constants';

export function getAvailableServicesWithName(
  appointmentTypes: AppointmentTypes,
  isRemote: boolean,
) {
  const { availableServices, defaultOptions } = appointmentTypes;
  const services = classifyServicesByType(availableServices, isRemote);
  const { adultServices, minorServices, otherServices } = services;

  const adultApptTypes = { availableServices: adultServices, defaultOptions };
  const minorApptTypes = { availableServices: minorServices, defaultOptions };
  const otherApptTypes = { availableServices: otherServices, defaultOptions };

  const adultApptTypesWithNames = getAppointmentTypesWithNames(adultApptTypes);
  const minorApptTypesWithNames = getAppointmentTypesWithNames(minorApptTypes);
  const otherApptTypesWithNames = getAppointmentTypesWithNames(otherApptTypes);

  return {
    adultApptTypesWithNames,
    minorApptTypesWithNames,
    otherApptTypesWithNames,
  };
}

function getAppointmentTypesWithNames(
  appointmentType: AppointmentTypes,
): DefaultOption[] {
  const { availableServices, defaultOptions } = appointmentType;

  if (!availableServices.length) return [];

  return availableServices.map(service => {
    const { configOptions } = service;
    const services = matchServicesWithDefaultOptions(
      configOptions,
      defaultOptions,
    );
    return services[0];
  });
}

function classifyServicesByType(
  availableServices: AvailableService[],
  isRemote: boolean,
) {
  const adultServices = availableServices.filter(({ configOptions }) => {
    const isAdultService = configOptions.includes(ServicesProfiles.Adult);

    if (!isRemote) return isAdultService;

    const isVideoAssistedService = configOptions.includes(
      ScheduleConfigurations.VideoAssisted,
    );
    return isAdultService && isVideoAssistedService;
  });

  const minorServices = availableServices.filter(({ configOptions }) => {
    const isMinorService = configOptions.includes(ServicesProfiles.Minor);

    if (!isRemote) return isMinorService;

    const isVideoAssistedService = configOptions.includes(
      ScheduleConfigurations.VideoAssisted,
    );
    return isMinorService && isVideoAssistedService;
  });
  const otherServices = availableServices.filter(({ configOptions }) => {
    const isAdultService = configOptions.includes(ServicesProfiles.Adult);
    const isMinorService = configOptions.includes(ServicesProfiles.Minor);
    const isOtherService = !isAdultService && !isMinorService;

    if (!isRemote) return isOtherService;

    const isVideoAssistedService = configOptions.includes(
      ScheduleConfigurations.VideoAssisted,
    );
    return isOtherService && isVideoAssistedService;
  });

  return { adultServices, minorServices, otherServices };
}

function matchServicesWithDefaultOptions(
  configOptions: string[],
  defaultOptions: DefaultOption[],
): DefaultOption[] {
  return defaultOptions.reduce(
    (matchServices: DefaultOption[], defaultOption) => {
      const { optionIds } = defaultOption;
      const isSame = configOptions.every(configOption =>
        optionIds.includes(configOption),
      );

      if (!isSame) return matchServices;

      matchServices.push(defaultOption);
      return matchServices;
    },
    [],
  );
}

export function getFilteredOptions(
  productName: string,
  optionsArray: DefaultOption[]
): string[] {
  const matchingProduct = optionsArray.find(
    (product) => product.productName === productName
  );

  if (!matchingProduct) {
    return [];
  }

  const validOptions = ['ADULT', 'IN_PERSON', 'VIDEO_ASSISTED', 'MINOR'];
  const filteredOptions = matchingProduct.optionIds.filter(
    (option) => !validOptions.includes(option)
  );

  if (filteredOptions.length > 0) {
    return [filteredOptions[0]]; 
  }

  return [];
}

export function getKeysWithTrueValues(obj: { [key: string]: boolean }): string[] {
  return Object.keys(obj).filter((key) => obj[key] === true);
}

export function processCheckedServices(
  newCheckedServices: { [key: string]: boolean },
  appTypesWithNames: DefaultOption[]
) {
  const updatedCheckedServices: { [key: string]: boolean } = {};
  let hasTrueValue = false;

  Object.keys(newCheckedServices).forEach((productName) => {
    const option = getFilteredOptions(productName, appTypesWithNames);
    if (option.length > 0) {
      updatedCheckedServices[option[0]] = newCheckedServices[productName];

      if (newCheckedServices[productName]) {
        hasTrueValue = true;
      }
    }
  });

  return { updatedCheckedServices, hasTrueValue };
}
