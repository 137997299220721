import styled from '@emotion/styled';

export const BottomFooter = styled.div`
  bottom: 0; 
  left: 0;  
  margin-left: auto;
  margin-right: auto;
  width: 692px;   
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;
