import React, { InputHTMLAttributes } from 'react';

import { RadioInput, RadioLabel, Container } from './style';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  disabled?: boolean;
}

const Radio: React.FC<Props> = (props: Props) => {
  const { id, label, disabled, ...rest } = props;
  return (
    <Container>
      <RadioInput id={id} type="radio" name="appointment-type" disabled={disabled} {...rest} />
      <RadioLabel htmlFor={id} disabled={disabled}>{label}</RadioLabel>
    </Container>
  );
};

export default Radio;
