import React, { useState, HTMLAttributes } from 'react';

import { Facility } from '../../../types/userProfile';
import {
  DropdownContainer,
  DropdownButton,
  DropdownIcon,
  DropdownMenuItem,
  DropdownMenu,
} from './styles';

interface Props {
  retailStores: Facility[];
  onChange: (option: string, store: Facility) => void;
  disabled: boolean;
}

export interface DropdownMenuProps extends HTMLAttributes<HTMLUListElement> {
  isOpen: boolean;
}

const FacilityPicker: React.FC<Props> = (props: Props) => {
  const { retailStores, onChange, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onToggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const onChangeCb = (
    event: React.MouseEvent<HTMLLIElement>,
    store: Facility,
  ) => {
    const selectedOption = event.currentTarget.textContent;
    setIsOpen(false);
    onChange(selectedOption || '', store);
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={onToggleDropdown}>
        <DropdownIcon />
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {retailStores.map(store => (
          <DropdownMenuItem
            key={store.id}
            onClick={(event: React.MouseEvent<HTMLLIElement>) =>
              onChangeCb(event, store)
            }
            disabled={disabled}
          >
            {store.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default FacilityPicker;
