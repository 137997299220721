import { DoctorsData } from '../../../types/doctors';

export const filterDoctorsWithFullName = (data: DoctorsData[], query: string) => {
  if (!data) {
    return [];
  }

  const normalizedQuery = query.toLowerCase(); 
  return data.filter(doctor => {
    const fullName = (doctor.firstName + ' ' + doctor.lastName).toLowerCase();
    
    return fullName.includes(normalizedQuery);
  });
};
