import { useHttpClient } from '../../contexts/http-client';
import {
  CreateScheduleRequestDTO,
  CreateScheduleResponseDTO,
} from '../../types/schedules/dto';
import environments from '../environments';

export const useCreateSchedule = () => {
  const httpClient = useHttpClient();

  return async (data: CreateScheduleRequestDTO) => {
    const { apiUrl } = environments;
    const url = `${apiUrl}/api/v1/doctor/schedules`;
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    return await httpClient<CreateScheduleResponseDTO>(request, {
      responseModifier: async res => await res.json(),
    });
  };
};
