import React from 'react';

import { DoctorNameTextContainer, EditContainer } from './styles';

interface Props {
  doctorFullName: string;
  onClick: () => void;
}
const DoctorNameTextField: React.FC<Props> = (props: Props) => {
  const { doctorFullName, onClick } = props;

  return (
    <DoctorNameTextContainer onClick={onClick}>
      <span>Dr. {doctorFullName}</span>
      <EditContainer>Edit</EditContainer>
    </DoctorNameTextContainer>
  );
};

export default DoctorNameTextField;
