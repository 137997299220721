import React from 'react';

import { useLocalState } from '../../../store/create-schedule-page/StateProvider';
import Content from '../../atoms/content';
import Page from '../../atoms/page';
import LoadingSpinner from '../../atoms/spinner';
import Header from '../../organisms/header';
import ScheduleForm from '../../organisms/schedule-form';

const CreateSchedulePageRoot: React.FC = () => {
  const { loading } = useLocalState();

  return (
    <Page>
      <Header disabled />
      <Content>
        {loading ? <LoadingSpinner size="lg" /> : <ScheduleForm.Create />}
      </Content>
    </Page>
  );
};

export default CreateSchedulePageRoot;
