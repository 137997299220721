import { TitleContainer } from './styles';

interface MotalTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {}

export const MotalTitle: React.FC<MotalTitleProps> = ({
  children,
  ...props
}) => {
  return <TitleContainer {...props}>{children}</TitleContainer>;
};
