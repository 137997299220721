import styled from '@emotion/styled';
import { colors } from '@warbyparker/retail-design-system';

export const AppointmentTypeInputContainer = styled.div`
  display: block;
`;

export const ClearButtonContainer = styled.div`
  display: block;
  margin-bottom: 0.8rem;
  text-align: end;
  align-items: center;
`;

export const ClearButton = styled.a`
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.blueHover};
`;

export const AppointmentTypeOptionsContainer = styled.div`
  display: flex;
`;
