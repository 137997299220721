import React from 'react';
import { Root, createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import HttpClientProvider from './providers/http-client';
import AuthProvider from './store/auth/StateProvider';
import FacilityServicesProvider from './store/facility-services/StateProvider';
import HeaderProvider from './store/header-component/StateProvider';
import UserProfileProvider from './store/user-profile/StateProvider';

const root: Root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <HeaderProvider>
        <HttpClientProvider>
          <UserProfileProvider>
            <FacilityServicesProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </FacilityServicesProvider>
          </UserProfileProvider>
        </HttpClientProvider>
      </HeaderProvider>
    </AuthProvider>
  </React.StrictMode>,
);
