import { AppointmentTypes } from '../../types/schedules';
import {
  FACILITY_SERVICES_FAILURE,
  FACILITY_SERVICES_REQUEST,
  FACILITY_SERVICES_SUCCESS,
  FacilityServiceFailure,
  FacilityServicesRequest,
  FacilityServiceSuccess,
} from './actions';

export function startFacilityServicesRequest(): FacilityServicesRequest {
  return {
    type: FACILITY_SERVICES_REQUEST,
  };
}

export function setFacilityServicesSuccess(
  payload: AppointmentTypes,
): FacilityServiceSuccess {
  return {
    type: FACILITY_SERVICES_SUCCESS,
    payload,
  };
}

export function setFacilityServicesFailure(): FacilityServiceFailure {
  return {
    type: FACILITY_SERVICES_FAILURE,
  };
}
