import { ReactNode, useContext, useReducer } from 'react';

import Context from './context';
import reducer from './reducer';
import { initialState } from './state';

interface Props {
  children: ReactNode;
}

export function StateProvider(props: Props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}

export function useLocalState() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('state should be provided in Context.Provider');
  }

  const { state } = context;
  return state;
}

export function useLocalDispatch() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('dispatch should be provided in Context.Provider');
  }

  const { dispatch } = context;
  return dispatch;
}

export default StateProvider;
