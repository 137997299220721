import { AppointmentTypes } from '../../types/schedules';

export const FACILITY_SERVICES_REQUEST = 'FACILITY_SERVICES_REQUEST';
export const FACILITY_SERVICES_SUCCESS = 'FACILITY_SERVICES_SUCCESS';
export const FACILITY_SERVICES_FAILURE = 'FACILITY_SERVICES_FAILURE';

export interface FacilityServicesRequest {
  type: typeof FACILITY_SERVICES_REQUEST;
}

export interface FacilityServiceSuccess {
  type: typeof FACILITY_SERVICES_SUCCESS;
  payload: AppointmentTypes;
}

export interface FacilityServiceFailure {
  type: typeof FACILITY_SERVICES_FAILURE;
}

export type Action =
  | FacilityServicesRequest
  | FacilityServiceSuccess
  | FacilityServiceFailure;
