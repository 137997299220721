import { DoctorsData } from '../../types/doctors';
import { SchedulestData } from '../../types/schedules';

export const SCHEDULES_REQUEST = 'SCHEDULES_REQUEST';
export const SCHEDULES_SUCCESS = 'SCHEDULES_SUCCESS';
export const SCHEDULES_FAILURE = 'SCHEDULES_FAILURE';
export const SCHEDULES_SET_DOCTOR = 'SCHEDULES_SET_DOCTOR';
export const SCHEDULES_SET_START_AT_TIME = 'SCHEDULES_SET_START_AT_TIME';
export const SCHEDULES_SET_END_AT_TIME = 'SCHEDULES_SET_END_AT_TIME';
export const SCHEDULES_SET_SCHEDULES_TYPE = 'SCHEDULES_SET_SCHEDULES_TYPE';
export const SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES = 'SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES';
export const SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES = 'SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES';
export const SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES = 'SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES';
export const EDIT_SCHEDULES_REQUEST = 'EDIT_SCHEDULES_REQUEST';
export const EDIT_SCHEDULES_SUCCESS = 'EDIT_SCHEDULES_SUCCESS';
export const EDIT_SCHEDULES_FAILURE = 'EDIT_SCHEDULES_FAILURE';
export const SCHEDULE_SET_APPOINTMENT_TYPES_ARRAY = 'SCHEDULE_SET_APPOINTMENT_TYPES_ARRAY';

export interface SchedulestRequest {
  type: typeof SCHEDULES_REQUEST;
}

export interface SchedulestSuccess {
  type: typeof SCHEDULES_SUCCESS;
  payload: SchedulestData;
}

export interface SchedulestFailure {
  type: typeof SCHEDULES_FAILURE;
}

export interface SchedulestSetDoctor {
  type: typeof SCHEDULES_SET_DOCTOR;
  payload: DoctorsData | undefined;
}

export interface SchedulestSetStartAtTime {
  type: typeof SCHEDULES_SET_START_AT_TIME;
  payload: string;
}

export interface SchedulestSetEndAtTime {
  type: typeof SCHEDULES_SET_END_AT_TIME;
  payload: string;
}

export interface SchedulestSetSchedulestType {
  type: typeof SCHEDULES_SET_SCHEDULES_TYPE;
  payload: string;
}

export interface ScheduleSetAppointmentTypesAdultServices {
  type: typeof SCHEDULE_SET_APPOINTMENT_TYPES_ADULT_SERVICES;
  payload: string[];
}

export interface ScheduleSetAppointmentTypesMinorServices {
  type: typeof SCHEDULE_SET_APPOINTMENT_TYPES_MINOR_SERVICES;
  payload: string[];
}

export interface ScheduleSetAppointmentTypesOtherServices {
  type: typeof SCHEDULE_SET_APPOINTMENT_TYPES_OTHER_SERVICES;
  payload: string[];
}
export interface EditSchedulestRequest {
  type: typeof EDIT_SCHEDULES_REQUEST;
}

export interface EditSchedulestSuccess {
  type: typeof EDIT_SCHEDULES_SUCCESS;
}

export interface EditSchedulestFailure {
  type: typeof EDIT_SCHEDULES_FAILURE;
}

export type Action =
  | SchedulestRequest
  | SchedulestSuccess
  | SchedulestFailure
  | SchedulestSetDoctor
  | SchedulestSetStartAtTime
  | SchedulestSetEndAtTime
  | SchedulestSetSchedulestType
  | ScheduleSetAppointmentTypesAdultServices
  | ScheduleSetAppointmentTypesMinorServices
  | ScheduleSetAppointmentTypesOtherServices
  | EditSchedulestRequest
  | EditSchedulestSuccess
  | EditSchedulestFailure;
