import StateProvider from '../../../store/home-page/StateProvider';
import HomePageRoot from './home-page-root';

const HomePage = () => {
  return (
    <StateProvider>
      <HomePageRoot />
    </StateProvider>
  );
};

export default HomePage;
