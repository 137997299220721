import React from 'react';

import LoadingIndicator from '../../atoms/loading-indicator';
import Page from '../../atoms/page';

interface Props {
  text?: string;
}
const LoadingIndicatorPage = (props: Props) => {
  const { text } = props;

  return (
    <Page>
      <LoadingIndicator text={text} />
    </Page>
  );
};

export default LoadingIndicatorPage;
