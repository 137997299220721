import { Reducer } from 'react';

import { produce } from 'immer';

import { Action } from './actions';
import { initialState, State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'FACILITY_SERVICES_REQUEST':
      draft.loading = true;
      draft.error = false;
      draft.facilityServices = initialState.facilityServices;
      break;
    case 'FACILITY_SERVICES_SUCCESS':
      draft.loading = false;
      draft.error = false;
      draft.facilityServices = action.payload;
      break;
    case 'FACILITY_SERVICES_FAILURE':
      draft.loading = false;
      draft.error = true;
      draft.facilityServices = initialState.facilityServices;
      break;
  }
});

export default reducer;
