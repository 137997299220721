import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

export const StyledHeader = styled('div')`
  align-items: center;
  background: white;
  display: flex;
  height: 78px;
  justify-content: space-between;
  left: 0;
  padding: 0 30px;
  position: fixed;
  top: 0;
  right: 0;
`;

export const WidthSetter = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 768px;
  width: 768px;
`;

export const Title = styled.div`
  flex-grow: 1;
`;

export const FacilityContainer = styled('div')`
  color: ${colors.charcoal};
  font-size: 18px;
  margin: 0;
  text-align: center;
`;

export const FacilityNameLabel = styled('span')`
  font-weight: 600;
`;

export const EndBlock = styled('div')`
  display: block;
  height: 26px;
  width: 30px;
`;

export const ButtonRigthContainer = styled.div`
  width: 35px;
  display: flex;
  justify-content: end;
  gap: 12px;
`;
