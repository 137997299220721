import { forwardRef, useCallback, useImperativeHandle } from 'react';

import { useToggle } from '../../../../hooks/useToggle';
import Portal from '../../../atoms/portal';
import { StyledBackdrop, StyledContainer } from './styles';

interface ModalContainerProps extends React.HTMLAttributes<HTMLSpanElement> {}

export interface ModalContainerRef {
  closeModal: () => void;
  openModal: () => void;
}

const Container: React.ForwardRefRenderFunction<
  ModalContainerRef,
  ModalContainerProps
> = ({ children, ...props }, ref) => {
  const [isOpen, toggleOpen] = useToggle(false);

  const closeModal = useCallback(() => {
    toggleOpen(false);
  }, []);

  const openModal = useCallback(() => {
    toggleOpen(true);
  }, []);

  useImperativeHandle(ref, () => ({
    closeModal,
    openModal,
  }));

  return (
    <Portal element={document.body}>
      {isOpen && (
        <StyledContainer {...props}>
          <StyledBackdrop>{children}</StyledBackdrop>
        </StyledContainer>
      )}
    </Portal>
  );
};

export const ModalContainer = forwardRef(Container);
