import React from 'react';

import ErrorMessage from '../../atoms/error-message';
import Page from '../../atoms/page';

interface Props {
  text?: string;
}
const ErrorPage = (props: Props) => {
  const { text } = props;

  return (
    <Page>
      <ErrorMessage text={text} />
    </Page>
  );
};

export default ErrorPage;
