import styled from '@emotion/styled';

import { colors } from '../../../styles/colors';

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${colors.stone};
`;

export const CardTitle = styled.h3`
  text-align: center;
  color: ${colors.stone};
`;

export const CardActionContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${colors.blueHover};
  border-radius: 99999px;

  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease-in;
  cursor: pointer;

  &.hidden {
    border: 0;

    &:hover {
      cursor: auto;
    }
  }

  & > span {
    color: ${colors.blueHover};
  }

  &:hover {
    border-color: ${colors.darkBlue};
    & > span {
      color: ${colors.darkBlue};
    }
  }
`;

export const CardSubtitle = styled.h4`
  text-align: center;
  color: ${colors.stone};

  margin-top: 1rem;
`;

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  column-gap: 0.3rem;
  row-gap: 1.5rem;
  width: 90%;
  > :nth-of-type(1) {
    grid-column: span 2;
  }
  > :nth-of-type(2) {
    grid-column: span 2;
  }
  > :nth-of-type(3) {
    grid-column: span 2;
  }
  > :nth-of-type(4) {
    grid-column: span 2;
  }
  > :nth-of-type(5) {
    grid-column: span 4;
  }
  > :nth-of-type(6) {
    grid-column: span 4;
  }
`;

export const SaveScheduleButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ScheduleErrorMessage = styled.p`
  color: ${colors.red};
  justify-content: center;
  text-align: center;
`;
