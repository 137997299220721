import { UserProfile } from '../../types/userProfile';
import {
  USER_PROFILE_FAILURE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SET_OFFICE,
  USER_PROFILE_SUCCESS,
  UserProfileFailure,
  UserProfileRequest,
  UserProfileSetOffice,
  UserProfileSuccess,
} from './actions';

export function startUserProfileRequest(): UserProfileRequest {
  return {
    type: USER_PROFILE_REQUEST,
  };
}

export function setUserProfileSuccess(
  payload: UserProfile,
): UserProfileSuccess {
  return {
    type: USER_PROFILE_SUCCESS,
    payload,
  };
}

export function setUserProfileFailure(): UserProfileFailure {
  return {
    type: USER_PROFILE_FAILURE,
  };
}

export function setOffice(payload: string): UserProfileSetOffice {
  return {
    type: USER_PROFILE_SET_OFFICE,
    payload,
  };
}
