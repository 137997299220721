import _ from 'lodash';

import { GroupedSchedule, Schedules } from '../../../types/schedules';

export function groupSchedulesByStartDate(
  schedulesData: Schedules,
): GroupedSchedule {
  const { data } = schedulesData;

  if (!data) return {};

  const groupedSchedules = _.groupBy(
    data,
    schedule => schedule.start.split('T')[0],
  );
  const scheduleDates = Object.keys(groupedSchedules);
  return _.orderBy(scheduleDates, undefined, ['desc']).reduce<GroupedSchedule>(
    (orderedObject, date) => {
      orderedObject[date] = groupedSchedules[date];
      return orderedObject;
    },
    {},
  );
}

/**
 * Temporary function until PMP refactor their services
 * to avoid listing de-sync (similar to waitlist)
 */
export function filterOutScheduleOfId(
  scheduleData: Schedules,
  ...ids: string[]
): Schedules {
  const newData = scheduleData.data.filter(
    schedule => !ids.includes(schedule.id),
  );

  const hasDiff = newData.length !== scheduleData.data.length;

  return {
    ...scheduleData,
    count: hasDiff ? scheduleData.count - 1 : scheduleData.count,
    total: hasDiff ? scheduleData.total - 1 : scheduleData.total,
    data: newData,
  };
}
