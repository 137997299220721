import React from 'react';

import { GroupedSchedule } from '../../../types/schedules';
import {
  TableContainer,
  TableWrapper,
  Table,
  TableHeader,
  NoDataCell,
  HiddenTableHeader,
} from '../../atoms/table';
import ExamRoomsRow from '../../molecules/exam-rooms-row';
import { CollapsibleExamRoomsRow } from '../../molecules/collapsible-exam-room-row';

interface Props {
  data: GroupedSchedule;
}

const SchedulesTable: React.FC<Props> = ({ data }) => {
  const scheduleDates = Object.keys(data);
  const isDataAvailable = scheduleDates.length > 0;

  return (
    <TableContainer>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <HiddenTableHeader></HiddenTableHeader>
              <TableHeader>Date</TableHeader>
              <TableHeader>Exam Room 1</TableHeader>
              <TableHeader>Exam Room 2</TableHeader>
              <TableHeader>Exam Room 3</TableHeader>
            </tr>
          </thead>
          <tbody>
    {isDataAvailable ? (
      scheduleDates.map((date: string,index:number) => {
        const hasMultipleSchedules = data[date].length > 1;
        return (
            hasMultipleSchedules ? (
              <CollapsibleExamRoomsRow key={index} date={date} schedulesByDate={data[date]} />
            ) : (
              <ExamRoomsRow  key={index} date={date} schedulesByDate={data[date]} />
            )
        );
      })
    ) : (
      <tr>
        <NoDataCell colSpan={5}>No data available</NoDataCell>
      </tr>
    )}
  </tbody>
        </Table>
      </TableWrapper>
    </TableContainer>
  );
};

export default SchedulesTable;
