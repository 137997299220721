import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSchedule } from '../../../data-access/schedules/use-schedule';
import {
  setSchedulestFailure,
  setSchedulestSuccess,
  startSchedulestRequest,
} from '../../../store/edit-appointment-page/action-creators';
import {
  useLocalDispatch,
  useLocalState,
} from '../../../store/edit-appointment-page/StateProvider';
import { SchedulestData } from '../../../types/schedules';
import Content from '../../atoms/content';
import Page from '../../atoms/page';
import LoadingSpinner from '../../atoms/spinner';
import Header from '../../organisms/header';
import ScheduleForm from '../../organisms/schedule-form';

const EditSchedulePageRoot: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { schedule, loading } = useLocalState();
  const dispatch = useLocalDispatch();
  const getSchedules = useSchedule();

  useEffect(() => {
    onScheduleRequest(id);
  }, [id]);

  const onScheduleRequest = (id: string) => {
    dispatch(startSchedulestRequest());
    getSchedules(id).then(onScheduleSuccess).catch(onScheduleFailure);
  };

  const onScheduleSuccess = (response: SchedulestData) => {
    dispatch(setSchedulestSuccess(response));
  };

  const onScheduleFailure = () => {
    dispatch(setSchedulestFailure());
  };

  return (
    <Page>
      <Header disabled />
      <Content>
        {loading || !schedule ? (
          <LoadingSpinner size="lg" />
        ) : (
          <ScheduleForm.Edit />
        )}
      </Content>
    </Page>
  );
};

export default EditSchedulePageRoot;
