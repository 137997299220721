import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { useEventListener } from '../../../hooks/useEventListener';
import { useToggle } from '../../../hooks/useToggle';
import Portal from '../portal';
import { ActionMenuList } from './styles';

interface ActionsMenuProps extends React.HTMLAttributes<HTMLSpanElement> {
  label: string;
}

export interface ActionsMenuRef {
  handleClose: () => void;
  handleOpen: React.MouseEventHandler<HTMLSpanElement>;
}

export const ActionsMenu = forwardRef<ActionsMenuRef, ActionsMenuProps>(
  (props, ref) => {
    const { children, label, ...rest } = props;
    const [coords, setCoords] = useState({ x: 0, y: 0 });
    const [isOpen, toggleOpen] = useToggle(false);

    const handleOpen = useCallback<React.MouseEventHandler<HTMLSpanElement>>(
      evt => {
        evt.stopPropagation();

        setCoords({
          x: evt.pageX,
          y: evt.pageY,
        });

        toggleOpen(true);
      },
      [],
    );

    const handleClose = useCallback(() => {
      toggleOpen(false);

      setCoords({ x: 0, y: 0 });
    }, []);

    useImperativeHandle(ref, () => ({
      handleClose,
      handleOpen,
    }));

    useEventListener(document.body, 'click', handleClose);

    return (
      <>
        <span onClick={handleOpen} {...rest}>
          {label}
        </span>

        {isOpen && (
          <Portal element={document.body}>
            <ActionMenuList x={coords.x} y={coords.y}>
              {children}
            </ActionMenuList>
          </Portal>
        )}
      </>
    );
  },
);
