import styled from '@emotion/styled';

interface Props {
  disableClick?: boolean;
}

const Section = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  padding: 0 6px;
  flex-direction: column;
  flex: 1;
  align-self: end;
  cursor: ${(props) => (props.disableClick ? 'no-drop' : 'pointer')}; 
`;

export default Section;
