import React from 'react';

import ButtonContainer from './styles';

export interface Props {
  onClick: () => void;
  icon?: React.ReactNode;
}

const Button: React.FC<Props> = (props: Props) => {
  const { icon, onClick } = props;

  if (icon) {
    return <ButtonContainer onClick={onClick}>{icon}</ButtonContainer>;
  }
  return <ButtonContainer onClick={onClick} />;
};

export default Button;
