import { Reducer } from 'react';

import { produce } from 'immer';

import { Action } from './actions';
import { State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'SCHEDULES_REQUEST':
      draft.loading = true;
      draft.error = false;
      break;
    case 'SCHEDULES_SUCCESS':
      draft.schedules = action.payload;
      draft.loading = false;
      draft.error = false;
      break;
    case 'SCHEDULES_FAILURE':
      draft.schedules = undefined;
      draft.loading = false;
      draft.error = true;
      break;
    case 'SCHEDULES_CHANGE_MONTH':
      draft.currentMonth = action.payload;
      break;
    case 'SCHEDULES_CHANGE_YEAR':
      draft.currentYear = action.payload;
      break;
  }
});

export default reducer;
