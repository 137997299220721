import React from 'react';

import { Spinner } from './styles';
import { Size } from './types';

interface Props {
  size?: Size;
}

export const LoadingSpinner: React.FC<Props> = (props: Props) => {
  const { size = 'md' } = props;
  return <Spinner size={size} />;
};

export default LoadingSpinner;
