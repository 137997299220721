import { UserProfile } from '../../types/userProfile';

export interface State {
  userProfile: UserProfile;
  error: boolean;
  loading: boolean;
}

export const initialState: State = {
  userProfile: {
    user: null,
    retail_stores: [],
    facility: null,
    features: [],
    permissions: [],
    environment: null,
    office: null,
  },
  loading: false,
  error: false,
};
